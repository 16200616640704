import * as APIs from "@/helpers/Apis/Productions/ProductionDetails";
import i18n from "@/plugins/i18n/i18n";

const state = {
  errors: [],
  isProductionDetailsLoaded: true,
};

const getters = {
  isProductionDetailsLoaded(state) {
    return state.isProductionDetailsLoaded;
  },
  getProductionDetailErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setisProductionDetailsLoaded: (state, payload) => {
    state.isProductionDetailsLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
};

const actions = {
  // productionDetails
  checkDeleteDetail: ({ commit }, data) => {
    commit("setisProductionDetailsLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.checkDeleteDetail(data)
        .then((res) => {
          resolve(res);
          commit("setErrors", []);
          commit("setisProductionDetailsLoaded", true);
        })
        .catch((error) => {
          commit("setisProductionDetailsLoaded", true);
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  deleteProductionDetail: ({ commit }, id) => {
    commit("setisProductionDetailsLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.deleteProductionDetail(id)
        .then((res) => {
          resolve(res);
          commit("setErrors", []);
          commit("setisProductionDetailsLoaded", true);
        })
        .catch((error) => {
          commit("setisProductionDetailsLoaded", true);
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
