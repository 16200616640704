// import { InvoiceTypeIds } from "@/helpers/Constants/General";
import { toFixedIfNecessary } from "@/helpers/HelperFunctions";
import { PurchaseAPI } from "@/helpers/Apis/Invoices/Purchases/Purchases";
import { EntryDetailAPI } from "@/helpers/Apis/Entries/EntryDetails";
import i18n from "@/plugins/i18n/i18n";
import router from "@/router";

const state = {
  purchase: {},

  errors: [],
  purchaseDetailsErrors: [],
  isPurchasesLoaded: true,
  isEntriesLoaded: true,
};

const getters = {
  isPurchasesLoaded(state) {
    return state.isPurchasesLoaded;
  },
  getPurchase(state) {
    return state.purchase;
  },

  getPurchaseErrors(state) {
    return state.errors;
  },

  getPurchaseDetailsErrors(state) {
    return state.purchaseDetailsErrors;
  },

  isEntriesLoaded(state) {
    return state.isEntriesLoaded;
  },
};

const mutations = {
  setIsPurchasesLoaded: (state, payload) => {
    state.isPurchasesLoaded = payload;
  },
  setPurchase: (state, payload) => {
    state.purchase = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setPurchaseRelated(state, payload) {
    state.purchase.related_return = payload;
  },
  setExtraEntries(state, payload) {
    state.purchase.invoice_entries_extra = payload;
  },

  setPurchaseExpenses(state, payload) {
    state.purchase.expenses = payload;
  },

  addInvoiceEntries(state, payload) {
    state.purchase.invoice_entries_extra = [...state.purchase.invoice_entries_extra, ...payload];
    let expenses = toFixedIfNecessary(
      state.purchase.invoice_entries_extra.reduce((a, b) => a + +b["debit"], 0) ?? 0,
      2
    );
    state.purchase.expenses = expenses;
  },
  setInvoiceExpenses(state, payload) {
    if (payload) {
      state.purchase.expenses = payload;
    } else {
      let expenses = toFixedIfNecessary(
        state.purchase.invoice_entries_extra.reduce((a, b) => a + +b["debit"], 0) ?? 0,
        2
      );
      state.purchase.expenses = expenses;
    }
  },
  setIsEntriesLoaded(state, payload) {
    state.isEntriesLoaded = payload;
  },

  // addInvoiceDetail(state, payload) {
  //   state.purchase.invoice_details.push(payload);
  // },
};

const actions = {
  fetchPurchase: ({ commit }, id) => {
    commit("setIsPurchasesLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      PurchaseAPI.fetchPurchase(id)
        .then((res) => {
          commit("setPurchase", res.data.data);
          commit("setIsPurchasesLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", []);
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsPurchasesLoaded", true);
          reject(error);
        });
    });
  },
  previousInvoice: ({ commit }, id) => {
    commit("setIsPurchasesLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      PurchaseAPI.previousPurchase(id)
        .then((res) => {
          commit("setPurchase", res.data.data);
          commit("setIsPurchasesLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", []);

          router.push({
            path: `/purchases/${res.data.data.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsPurchasesLoaded", true);
          reject(error);
        });
    });
  },
  nextInvoice: ({ commit }, id) => {
    commit("setIsPurchasesLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      PurchaseAPI.nextPurchase(id)
        .then((res) => {
          commit("setPurchase", res.data.data);
          commit("setIsPurchasesLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", []);
          router.push({
            path: `/purchases/${res.data.data.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsPurchasesLoaded", true);
          reject(error);
        });
    });
  },

  createPurchase: ({ commit }, data) => {
    commit("setIsPurchasesLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      PurchaseAPI.createPurchase(data)
        .then((res) => {
          commit("setErrors", []);
          commit("setIsPurchasesLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setPurchase", invoice);
          router.push({
            path: `/purchases/${invoice.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsPurchasesLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  updatePurchase: ({ commit }, data) => {
    commit("setIsPurchasesLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      PurchaseAPI.updatePurchase(data)
        .then((res) => {
          commit("setErrors", []);
          commit("setIsPurchasesLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setPurchase", invoice);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsPurchasesLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
  deletePurchase: ({ commit }, data) => {
    commit("setIsPurchasesLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      PurchaseAPI.deletePurchase(data)
        .then((res) => {
          commit("setErrors", []);
          commit("setIsPurchasesLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsPurchasesLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  createCustomEntry: ({ state, commit }, data) => {
    commit("setIsEntriesLoaded", false);
    let invoiceData = state.purchase;
    let mergedData = { ...data, invoiceData: invoiceData };
    return new Promise((resolve, reject) => {
      EntryDetailAPI.createCustomEntry(mergedData)
        .then((res) => {
          commit("setErrors", []);
          commit("setIsEntriesLoaded", true);
          commit("addInvoiceEntries", res.data.data.entries);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEntriesLoaded", true);
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
