// Importing requirement
import store from "@/store";
import i18n from "@/plugins/i18n/i18n";

/**
 * Redirect to home page if not logged in and trying to access a restricted page.
 *
 * @param next
 * @param router
 */
export default function guest({ next, router }) {
  if (store.getters["User/getAuthentication"]) {
    next({ name: "Home", replace: true });
    iziToast.error({
      message: i18n.t("auth.logout"),
      type: "error",
    });
    return;
  }

  next();
}
