<template>
  <div>
    <v-dialog
      v-if="isDialog"
      :content-class="dialogContentClass"
      :max-width="maxWidth"
      v-model="overlay"
      :persistent="persistent"
      @keyup.esc="closeDialog"
      eager
    >
      <!-- <v-system-bar color="primary">
        <v-spacer></v-spacer>
        <v-btn v-if="!noBackBtn" icon @click="closeDialog" class="mb-1 closeBtn">
          <v-icon color="#fff">mdi-close</v-icon>
        </v-btn>
      </v-system-bar> -->

      <v-btn v-if="!noBackBtn" icon @click="closeDialog" class="closeBtn">
        <v-icon color="#fff">mdi-close</v-icon>
      </v-btn>

      <v-card class="elevation-12 rounded-0" :class="cardClass">
        <slot name="header" />

        <!-- :class="[cardTextClass, cardHasPadding ? 'pa-3' : 'pa-0']" -->
        <v-card-text class="dialogHeight" :class="cardTextClass">
          <slot name="content" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card v-else class="elevation-12 rounded-0">
      <slot name="header" />
      <v-card-text>
        <slot name="content" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    contentClass: {
      type: String,
      default: "",
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    isDialog: {
      type: Boolean,
      default: true,
    },
    noBackBtn: {
      type: Boolean,
      default: false,
    },
    fullDialogFrame: {
      type: Boolean,
      default: false,
    },
    outerFrame: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      overlay: false,
    };
  },
  computed: {
    dialogContentClass() {
      return this.contentClass || "";
    },
    maxWidth() {
      return this.contentClass ? "" : "600px";
    },
    cardClass() {
      return this.outerFrame
        ? this.fullDialogFrame
          ? "dialogFrame"
          : "dialogFrameTop"
        : "";
    },
    cardTextClass() {
      return !this.outerFrame
        ? this.fullDialogFrame
          ? "dialogFrame"
          : "dialogFrameTop"
        : "";
    },
  },
  methods: {
    openDialog() {
      this.overlay = true;
      return new Promise((resolve) => setTimeout(resolve, 100));
    },
    closeDialog() {
      this.overlay = false;
      return new Promise((resolve) => setTimeout(resolve, 100));
    },
  },

  watch: {
    overlay: function (newValue, old) {
      if (!newValue) {
        this.$emit("close");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogHeight {
  max-height: 80vh;
  overflow: auto;
  // padding-top: 1rem !important;
  // padding-bottom: 1rem !important;
  padding: 0 !important;
  display: grid;
}
// ::v-deep .dialogClass {
//   position: relative;
// }
.closeBtn {
  // position: absolute;
  // top: 0;
  // right: 0;
  // z-index: 9;
  background-color: var(--v-primaryBlue-base);
}

.dialogFrame {
  border: solid 3px var(--v-primary-base) !important;
}
.dialogFrameTop {
  border-bottom: solid 3px var(--v-primary-base) !important;
  border-left: solid 3px var(--v-primary-base) !important;
  border-right: solid 3px var(--v-primary-base) !important;
}
</style>
