import { MaintenanceAPI } from "@/helpers/Apis/Maintenance/Maintenances";
import i18n from "@/plugins/i18n/i18n";

const state = {
  reportData: [],
  errors: [],
  isMaintenanceReportLoaded: true,
  currentPage: null,

  SearchObject: {
    dates: {
      label: "date",
      value: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)],
    },
  },
};

const getters = {
  getReportData(state) {
    return state.reportData;
  },
  isMaintenanceReportLoaded(state) {
    return state.isMaintenanceReportLoaded;
  },
  getErrors(state) {
    return state.errors;
  },
  getCurrentPage(state) {
    return state.currentPage;
  },

  getSearchObject(state) {
    return state.SearchObject;
  },
};

const mutations = {
  setReportData: (state, payload) => {
    state.reportData = payload;
  },
  setIsMaintenanceReportLoaded: (state, payload) => {
    state.isMaintenanceReportLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setCurrentPage(state, payload) {
    state.reportData.meta.current_page = payload.value;
  },

  setSearchObject(state, payload) {
    state.SearchObject = payload;
  },
};

const actions = {
  fetchMaintenanceReport: ({ commit }, data) => {
    commit("setIsMaintenanceReportLoaded", false);
    return new Promise((resolve, reject) => {
      MaintenanceAPI.fetchMaintenanceReport(data)
        .then((res) => {
          commit("setErrors", []);
          commit("setReportData", res.data);
          commit("setIsMaintenanceReportLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMaintenanceReportLoaded", true);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
