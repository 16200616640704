import baseUrl from "@/constants/API";

export const RestaurantReservationAPI = {
  fetchRestaurantReservations(data) {
    return axios.post(
      baseUrl() + `/restaurant/reservations/get?page=${data.pageNumber}`,
      data.data
    );
  },

  fetchRestaurantReservation(id) {
    return axios.get(baseUrl() + `/restaurant/reservations/${id}`);
  },
  previousRestaurantReservation(id) {
    return axios.get(baseUrl() + `/restaurant/reservations/${id}/previous`);
  },
  nextRestaurantReservation(id) {
    return axios.get(baseUrl() + `/restaurant/reservations/${id}/next`);
  },

  createRestaurantReservation(data) {
    return axios.post(baseUrl() + `/restaurant/reservations/create`, data);
  },

  updateRestaurantReservation(data) {
    return axios.post(baseUrl() + `/restaurant/reservations/update/${data.id}`, data.data);
  },
  deleteRestaurantReservation(id) {
    return axios.delete(baseUrl() + `/restaurant/reservations/delete/${id}`);
  },
  setRestaurantReservationStatus(data) {
    return axios.post(baseUrl() + `/restaurant/reservations/setstatus`, data);
  },

  restaurantReservationConvertToOrder(data) {
    return axios.post(
      baseUrl() + `/restaurant/reservations/convert-to-order/${data.id}`,
      data.data
    );
  },
};
