<template>
  <v-app>
    <Drawer ref="drawerComponent" />

    <v-app-bar app dense class="d-print-none">
      <v-app-bar-nav-icon @click="drawerAction"></v-app-bar-nav-icon>

      <v-toolbar-title v-if="getAuthentication" class="w-100">
        <div class="d-flex align-center">
          <!-- @click="toHome"  class="pointer-cursor"-->
          <h5>
            {{ getCompanyData.display_name }}
          </h5>

          <v-spacer></v-spacer>

          <h6 v-if="getCompanyData.level">
            level: {{ getCompanyData.level }}/
          </h6>

          <h6 v-if="getCompanyData.renewal_date">
            {{ $t("expiry") }}: {{ getCompanyData.renewal_date }}
          </h6>
        </div>
      </v-toolbar-title>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <FooterComponent />

    <v-overlay class="app-overlay" :value="getAppLoader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <SpeedDial v-if="showBack" class="d-print-none" />
  </v-app>
</template>

<script>
import FooterComponent from "@/views/SharedComponents/FooterComponent";
import SpeedDial from "@/views/SharedComponents/SpeedDial";

import Drawer from "@/views/SharedComponents/Drawer/Drawer.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { setDocumentDirectionPerLocale } from "./plugins/i18n/document";
export default {
  name: "App",
  components: {
    Drawer,
    SpeedDial,
    FooterComponent,
  },
  methods: {
    ...mapActions("User", ["fetchUser"]),
    ...mapActions("Sockets", ["registerPublicChannel"]),
    ...mapActions("Sockets", ["registerCompanyChannel"]),
    ...mapMutations("App", ["updateOnlineStatus"]),

    drawerAction() {
      this.$refs.drawerComponent.drawerAction();
    },
    toHome() {
      if (this.$route.path !== "/") {
        this.$router.push({
          path: `/`,
        });
      }
    },
    // routerBack() {
    //   return this.$router.go(-1);
    // },
  },
  computed: {
    ...mapGetters("App", ["getAppLoader"]),
    ...mapGetters("User", ["getAuthentication"]),
    showBack() {
      return this.$route.name == "Cashier" ||
        this.$route.name == "Cashier2" ||
        !this.getAuthentication
        ? false
        : true;
    },
  },
  created() {
    if (this.getUser) {
      return;
    }
    const token = this.$VueCookies.get("token");
    if (token) {
      return this.fetchUser();
    }
  },
  mounted() {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);

    this.registerPublicChannel();
    this.registerCompanyChannel();

    this.$watch(
      "$i18n.locale",
      (newLocale, oldLocale) => {
        newLocale == "AR"
          ? (this.$vuetify.rtl = true)
          : (this.$vuetify.rtl = false);
        if (newLocale === oldLocale) {
          return;
        }
        setDocumentDirectionPerLocale(newLocale);
      },
      { immediate: true }
    );
  },
};
</script>

<style lang="scss">
.question-toast {
  .mdi {
    font-size: 1.8rem !important;
  }
  .iziToast-message {
    font-size: 1.2rem !important;
    font-weight: bold;
  }
}
</style>
