import Vue from "vue";
// import Permissions from "@/helpers/Permissions/Permissions.js";
import { writeFile, utils } from "xlsx";

const excelMixins = {
  computed: {},
  methods: {
    // data example
    // this.basicExportExcel([
    //   {
    //     title: "First Dataset",
    //     tableHeaders: [
    //       { text: "Item", value: "item.name", keyValue: "Item" },
    //       { text: "Price", value: "price", keyValue: "Price" },
    //       { text: "Unit", value: "unit", keyValue: "Unit" },
    //       { text: "Total", value: "total", keyValue: "Total" },
    //     ],
    //     data: table1Data,
    //     headersToSum: ["Price", "Total"],
    //     keyValuesToExclude: ["Unit"], // Exclude Unit for this dataset
    //   },
    //   {
    //     title: "Second Dataset",
    //     tableHeaders: [
    //       { text: "Product", value: "item.name", keyValue: "Product" },
    //       { text: "Cost", value: "cost", keyValue: "Cost" },
    //     ],
    //     data: table2Data,
    //     headersToSum: ["Cost"],
    //     keyValuesToExclude: [], // No exclusions for this dataset
    //   },
    // ]);
    // basicExportExcel(tablesData) {
    //   if (!tablesData || tablesData.length === 0) {
    //     return iziToast.error({
    //       message: this.$t("nodata"),
    //       type: "error",
    //     });
    //   }

    //   const tableExports = tablesData
    //     .map(
    //       ({
    //         tableHeaders,
    //         data,
    //         title,
    //         headersToSum = [],
    //         keyValuesToExclude = ["Number", "Actions"],
    //       }) => {
    //         if (!data || data.length <= 0) {
    //           return null;
    //         }

    //         const filteredHeaders = tableHeaders
    //           .filter(({ keyValue }) => !keyValuesToExclude.includes(keyValue))
    //           .map(({ text, value, keyValue }) => ({ text, value, keyValue }));
    //         const headers = filteredHeaders.map((header) => header.text);

    //         const getValue = (item, path, keyValue) => {
    //           if (keyValue === "Serials") {
    //             return this.serialsMap(item);
    //           } else if (keyValue === "Expire") {
    //             let expiration = item.expiration;
    //             if (!expiration) {
    //               return "";
    //             }
    //             let batchNumber = expiration?.batch_number
    //               ? ` ( ${expiration?.batch_number} )`
    //               : "";
    //             return `${expiration?.expire_date}${batchNumber}`;
    //           } else {
    //             return path.split(".").reduce((acc, part) => acc && acc[part], item) || "";
    //           }
    //         };

    //         const excelData = data.map((item) => {
    //           return filteredHeaders.reduce((obj, { text, value, keyValue }) => {
    //             obj[text] = getValue(item, value, keyValue);
    //             return obj;
    //           }, {});
    //         });

    //         return {
    //           headers,
    //           data: excelData,
    //           title,
    //           headersToSum,
    //           filteredHeaders,
    //         };
    //       }
    //     )
    //     .filter(Boolean); // Remove null entries

    //   // console.log(tableExports);
    //   this.toExcel(tableExports);
    // },

    // basicExportExcel([
    //   {
    //     tableHeaders: [
    //       { keyValue: "Name", text: "Name", value: "name" },
    //       { keyValue: "Barcode", text: "Barcode", value: "barcode" },
    //     ],
    //     data: [
    //       {
    //         name: "item1",
    //         barcode: "0001",
    //         storelogs: [
    //           { store: { name: "store1" }, unit: 5, piece: 0, item_cost: 20, total_costs: 100 },
    //           { store: { name: "store2" }, unit: 1, piece: 0, item_cost: 20, total_costs: 20 },
    //         ],
    //       },
    //       {
    //         name: "item2",
    //         barcode: "0003",
    //         storelogs: [
    //           { store: { name: "store1" }, unit: 6, piece: 0, item_cost: 30, total_costs: 180 },
    //         ],
    //       },
    //     ],
    //     title: "Main Table",
    //     nestedHeaders: [
    //       { keyValue: "Store", text: "Store", value: "store.name" },
    //       { keyValue: "Unit", text: "Unit", value: "unit" },
    //       { keyValue: "Piece", text: "Piece", value: "piece" },
    //       { keyValue: "Cost", text: "Cost", value: "item_cost" },
    //       { keyValue: "Total", text: "Total", value: "total_costs" },
    //     ],
    //     nestedKey: "storelogs",
    //   },
    // ]);
    basicExportExcel(tablesData) {
      if (!tablesData || tablesData.length === 0) {
        return iziToast.error({
          message: this.$t("nodata"),
          type: "error",
        });
      }

      const tableExports = tablesData
        .map(
          ({
            tableHeaders,
            data,
            title,
            headersToSum = [],
            keyValuesToExclude = ["Number", "Actions"],
            nestedHeaders = [],
            nestedKey = null, // e.g. "storelogs"
          }) => {
            // 1) Make sure we have data
            if (!data || data.length <= 0) {
              return null;
            }

            // 2) Filter out any unwanted parent columns
            const filteredHeaders = tableHeaders.filter(
              ({ keyValue }) => !keyValuesToExclude.includes(keyValue)
            );
            // Example result might be:
            // [ { keyValue: "Item", text: "Item", value: "name" },
            //   { keyValue: "Barcode", text: "Barcode", value: "barcode" },
            //   { keyValue: "StoreLogs", text: "stores", value: "storelogs" }]

            // 3) Build the final "top row" from the parent's text fields
            const parentHeaderTexts = filteredHeaders.map((h) => h.text);
            // For example: ["Item", "Barcode", "stores"]

            // This will be an array-of-arrays (AOA) that we'll eventually feed into XLSX.
            const excelRows = [];

            // // 4) Add a top row with those parent headers
            // //    e.g. ["Item", "Barcode", "stores"]
            // excelRows.push(parentHeaderTexts);

            // Helper function to safely get nested values (e.g., "store.name")
            const getValue = (obj, path) =>
              path.split(".").reduce((acc, part) => acc && acc[part], obj) || "";

            // 5) For each item (the "parent")
            data.forEach((item) => {
              // A) Row for the parent item
              //    e.g. [ itemName, barcode, '' ]
              const parentRow = filteredHeaders.map(({ value }, idx) => {
                // For columns that are actually the nestedKey (e.g. "storelogs"),
                // we might want to leave them blank so the layout looks nice.
                // But to keep it consistent, let's do a check:
                if (value === nestedKey) {
                  return ""; // empty cell
                }
                return getValue(item, value);
              });
              excelRows.push(parentRow);

              // B) Row with the nested headers under the correct "storelogs" column
              //    For example, if the 3rd column is "stores", from there we put
              //    [ "Name", "Unit", "Piece", ... ] horizontally.
              let storeColIndex = filteredHeaders.findIndex((h) => h.value === nestedKey);
              if (storeColIndex === -1) {
                // Means we don't have a valid column for store logs
                // (maybe user didn’t pass the correct key?). Just skip
                return;
              }

              // Create an empty row that has empty cells for the parent columns,
              // but from storeColIndex onward we place the nested header texts.
              const emptyRow = Array(parentHeaderTexts.length).fill("");
              nestedHeaders.forEach((nh, idx) => {
                emptyRow[storeColIndex + idx] = nh.text;
              });
              excelRows.push(emptyRow);

              // C) For each nested record (storelog)
              //    We add a row that has empty cells for the first columns,
              //    but from storeColIndex onward, the actual storelog data.
              if (nestedKey && item[nestedKey] && item[nestedKey].length > 0) {
                item[nestedKey].forEach((nestedItem) => {
                  const rowForNested = Array(parentHeaderTexts.length).fill("");
                  nestedHeaders.forEach((nh, idx) => {
                    rowForNested[storeColIndex + idx] = getValue(nestedItem, nh.value);
                  });
                  excelRows.push(rowForNested);
                });
              }
            });

            // Now we have an array-of-arrays for all the rows we want to export.
            // We'll attach it to a "data" field that our toExcel function can use.
            return {
              // The 'headers' param in this context can be optional or empty
              // because we're building the rows ourselves. But let's keep it consistent.
              headers: parentHeaderTexts,
              data: excelRows, // array-of-arrays
              title,
              headersToSum, // if you use sums
              filteredHeaders, // if you need them for sums
            };
          }
        )
        .filter(Boolean);

      // 6) Pass our tableExports to the final Excel writing function
      this.toExcel(tableExports);
    },

    toExcel(tables) {
      const workbook = utils.book_new();
      const worksheet = {};
      let currentRow = 1;

      tables.forEach(({ headers, data, title, headersToSum, filteredHeaders }) => {
        // Add title if provided
        if (title) {
          utils.sheet_add_aoa(worksheet, [[title]], { origin: `A${currentRow}` });
          // currentRow += 2; // Leave space after the title
          currentRow += 1;
        }

        // Add headers
        utils.sheet_add_aoa(worksheet, [headers], { origin: `A${currentRow}` });
        // // this only when including automatic calculations
        // const headerRow = currentRow; // Track header row for formulas
        currentRow++;

        // Add data
        utils.sheet_add_json(worksheet, data, {
          skipHeader: true,
          origin: `A${currentRow}`,
        });
        // // this only when including automatic calculations
        // const dataStartRow = currentRow; // Start of data rows
        // const dataEndRow = currentRow + data.length - 1; // End of data rows
        currentRow += data.length;

        // Calculate sums with formulas
        if (headersToSum.length > 0) {
          const sumRow = {};
          const sumHeaderIndexes = headersToSum
            .map((headerKeyValue) =>
              filteredHeaders.find(({ keyValue }) => keyValue === headerKeyValue)
            )
            .map((header) => (header ? headers.indexOf(header.text) : -1))
            .filter((index) => index !== -1);

          // // this only for automatic calculations
          // sumHeaderIndexes.forEach((index) => {
          //   // Excel columns start at 'A' (ASCII 65), so add index to get the column letter
          //   const columnLetter = String.fromCharCode(65 + index);
          //   const formula = `=SUM(${columnLetter}${dataStartRow}:${columnLetter}${dataEndRow})`;
          //   sumRow[headers[index]] = { f: formula }; // Insert formula under the correct header
          // });
          sumHeaderIndexes.forEach((index) => {
            const sum = data.reduce((acc, row) => acc + Number(row[index] || 0), 0);
            sumRow[headers[index]] = sum;
          });

          // Add the sum row
          const sumRowArray = headers.map((header) => (sumRow[header] ? sumRow[header] : ""));
          utils.sheet_add_aoa(worksheet, [sumRowArray], { origin: `A${currentRow}` });
          currentRow++;
        }

        // Add a blank row between tables
        currentRow++;
      });
      // Append the worksheet to the workbook
      utils.book_append_sheet(workbook, worksheet, "Sheet1");
      // Write the workbook to file
      writeFile(workbook, "a5Excel.xlsx");
    },
  },
};

Vue.mixin(excelMixins);
