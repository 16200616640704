import { CompanyAPI } from "@/helpers/Apis/Company";
import { UserAPI } from "@/helpers/Apis/User/Users";
import i18n from "@/plugins/i18n/i18n";

const state = {
  companyData: JSON.parse(localStorage.getItem("company")) || null,
  isCompanyLoaded: true,

  database: localStorage.getItem("db") || null,

  loginUsers: [],
  isUsersLoaded: true,

  errors: [],
};

const getters = {
  getCompanyData(state) {
    return state.companyData;
  },
  getDatabase(state) {
    return state.database;
  },
  isCompanyLoaded(state) {
    return state.isCompanyLoaded;
  },

  getLoginUsers(state) {
    return state.loginUsers;
  },
  isUsersLoaded(state) {
    return state.isUsersLoaded;
  },
  getErrors(state) {
    return state.errors;
  },

  companyLevelChecker: (state) => (value) => {
    return state.companyData.level >= parseInt(value);
  },
  companyPermissionChecker: (state) => (value) => {
    if (state.companyData?.permissions?.includes(value)) {
      return true;
    } else {
      return false;
    }
  },
};

const mutations = {
  setIsCompanyLoaded: (state, payload) => {
    state.isCompanyLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setLoginUsers: (state, payload) => {
    state.loginUsers = payload;
  },
  setIsUsersLoaded: (state, payload) => {
    state.isUsersLoaded = payload;
  },

  setDatabase: (state, payload) => {
    state.database = payload;
    localStorage.setItem("db", payload);
  },
  setCompanyData: (state, payload) => {
    state.companyData = payload;
    localStorage.setItem(
      "company",
      JSON.stringify({
        id: payload.id,
        email: payload.email,
        name: payload.name,
        display_name: payload.display_name,
        phone_number: payload.phone_number,
        tax_registeration: payload.tax_registeration,
        renewal_date: payload.renewal_date,
        logo: payload.logo,
        info_1: payload.info_1,
        info_2: payload.info_2,
        level: payload.level,
        permissions: payload.permissions,
      })
    );
  },
};

const actions = {
  companyLogin: ({ commit }, data) => {
    commit("setIsCompanyLoaded", false);
    return new Promise((resolve, reject) => {
      CompanyAPI.companyLogin(data)
        .then((res) => {
          commit("setErrors", []);
          commit("setIsCompanyLoaded", true);
          // commit("setCompanyData", res.data.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCompanyLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  fetchDatabases: ({ commit }, data) => {
    commit("setIsCompanyLoaded", false);
    return new Promise((resolve, reject) => {
      CompanyAPI.fetchDatabases(data)
        .then((res) => {
          commit("setErrors", []);
          commit("setIsCompanyLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCompanyLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  fetchDBUsers: ({ commit }, data) => {
    commit("setIsUsersLoaded", false);
    return new Promise((resolve, reject) => {
      UserAPI.fetchDBUsers(data)
        .then((res) => {
          commit("setLoginUsers", res.data.data);
          commit("setIsUsersLoaded", true);
          commit("setErrors", []);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsUsersLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updateCompany: ({ commit }, data) => {
    commit("setIsCompanyLoaded", false);
    return new Promise((resolve, reject) => {
      CompanyAPI.updateCompany(data)
        .then((res) => {
          commit("setCompanyData", res.data.data);
          commit("setIsCompanyLoaded", true);
          commit("setErrors", []);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCompanyLoaded", true);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updatePassword: ({ commit }, data) => {
    commit("setIsCompanyLoaded", false);
    return new Promise((resolve, reject) => {
      CompanyAPI.updatePassword(data)
        .then((res) => {
          commit("setIsCompanyLoaded", true);
          commit("setErrors", []);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCompanyLoaded", true);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updateLogo: ({ commit }, data) => {
    commit("setIsCompanyLoaded", false);
    return new Promise((resolve, reject) => {
      CompanyAPI.updateLogo(data)
        .then((res) => {
          commit("setCompanyData", res.data.data);
          commit("setIsCompanyLoaded", true);
          commit("setErrors", []);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCompanyLoaded", true);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
