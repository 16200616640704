import { DistrictAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  districts: [],
  errors: [],
  isDistrictsLoaded: false,
};

const getters = {
  isDistrictsLoaded(state) {
    return state.isDistrictsLoaded;
  },
  getDistricts(state) {
    return state.districts;
  },
  getDistrictErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsDistrictsLoaded: (state, payload) => {
    state.isDistrictsLoaded = payload;
  },
  setDistricts: (state, payload) => {
    state.districts = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
};

const actions = {
  fetchDistricts: ({ commit }) => {
    commit("setIsDistrictsLoaded", false);
    return new Promise((resolve, reject) => {
      DistrictAPI.fetchDistricts()
        .then((res) => {
          commit("setDistricts", res.data);
          commit("setIsDistrictsLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsDistrictsLoaded", true);
          reject(error);
        });
    });
  },

  createDistrict: ({ commit }, data) => {
    commit("setIsDistrictsLoaded", false);
    DistrictAPI.createDistrict(data)
      .then((response) => {
        commit("setIsDistrictsLoaded", true);
        commit("setErrors", []);
      })
      .catch((error) => {
        commit("setIsDistrictsLoaded", true);
        let nameError = error.response.data.errors
          ? Object.values(error.response.data.errors).flat()
          : [error.response.data.message];
        commit("setErrors", nameError);
      });
  },

  updateDistrict: ({ commit }, data) => {
    commit("setIsDistrictsLoaded", false);
    DistrictAPI.updateDistrict(data)
      .then((res) => {
        commit("setIsDistrictsLoaded", true);
        commit("setErrors", []);
      })
      .catch((error) => {
        commit("setIsDistrictsLoaded", true);

        let nameError = error.response.data.errors
          ? Object.values(error.response.data.errors).flat()
          : [error.response.data.message];
        commit("setErrors", nameError);
      });
  },
  deleteDistrict: ({ commit }, data) => {
    commit("setIsDistrictsLoaded", false);
    return new Promise((resolve, reject) => {
      DistrictAPI.deleteDistrict(data)
        .then((res) => {
          commit("setIsDistrictsLoaded", true);
          commit("setErrors", []);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsDistrictsLoaded", true);
          reject(error);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  specificDistricts: ({ commit }, cityId) => {
    commit("setIsDistrictsLoaded", false);
    DistrictAPI.getSpecificDistricts(cityId)
      .then((res) => {
        commit("setIsDistrictsLoaded", true);
        commit("setDistricts", res.data);
        commit("setErrors", []);
      })
      .catch((error) => {
        commit("setIsDistrictsLoaded", true);
        let nameError = error.response.data.errors
          ? Object.values(error.response.data.errors).flat()
          : [error.response.data.message];
        commit("setErrors", nameError);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
