// import { InvoiceTypeIds } from "@/helpers/Constants/General";
import * as APIs from "@/helpers/Apis/CleanningInvoice/CleanningInvoices";
import i18n from "@/plugins/i18n/i18n";
const state = {
  cleanninginvoices: [],
  errors: [],
  isCleanningInvoiceLoaded: true,

  currentPage: null,
};

const getters = {
  isCleanningInvoiceLoaded(state) {
    return state.isCleanningInvoiceLoaded;
  },
  getCleanningInvoices(state) {
    return state.cleanninginvoices;
  },
  getCleanningInvoiceErrors(state) {
    return state.errors;
  },

  getCurrentPage(state) {
    return state.currentPage;
  },
};

const mutations = {
  setIsCleanningInvoiceLoaded: (state, payload) => {
    state.isCleanningInvoiceLoaded = payload;
  },
  setCleanningInvoices: (state, payload) => {
    state.cleanninginvoices = payload;
  },
  setCleanningInvoiceStatus: (state) => {
    state.cleanninginvoices.data[0].status = !state.cleanninginvoices.data[0].status;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setCurrentPage(state, payload) {
    state.cleanninginvoices.meta.current_page = payload.value;
  },
};

const actions = {
  fetchCleanningInvoices: ({ commit }, data) => {
    commit("setIsCleanningInvoiceLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.fetchCleanningInvoices(data)
        .then((res) => {
          commit("setCleanningInvoices", res.data);
          commit("setIsCleanningInvoiceLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCleanningInvoiceLoaded", true);
          reject(error);
        });
    });
  },

  createCleanningInvoice: ({ commit, dispatch }, data) => {
    commit("setIsCleanningInvoiceLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.createCleanningInvoice(data)
        .then((response) => {
          commit("setIsCleanningInvoiceLoaded", true);
          commit("setErrors", []);
          resolve();
        })
        .catch((error) => {
          commit("setIsCleanningInvoiceLoaded", true);
          reject(error.response);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  updateCleanningInvoice: ({ commit, dispatch }, data) => {
    commit("setIsCleanningInvoiceLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.updateCleanningInvoice(data)
        .then((response) => {
          commit("setIsCleanningInvoiceLoaded", true);

          commit("setErrors", []);
          resolve(response);
        })
        .catch((error) => {
          commit("setIsCleanningInvoiceLoaded", true);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  deleteCleanningInvoice: ({ commit, dispatch }, id) => {
    commit("setIsCleanningInvoiceLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.deleteCleanningInvoice(id)
        .then((response) => {
          commit("setErrors", []);
          commit("setIsCleanningInvoiceLoaded", true);
          resolve(response);
        })
        .catch((error) => {
          commit("setIsCleanningInvoiceLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  collectInvoice: ({ commit }, id) => {
    commit("setIsCleanningInvoiceLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.collectInvoice(id)
        .then((res) => {
          commit("setErrors", []);
          commit("setIsCleanningInvoiceLoaded", true);
          commit("setCleanningInvoiceStatus");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCleanningInvoiceLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
