import baseUrl from "@/constants/API";

export const PurchaseReportAPI = {
  fetchPurchaseSearch(data) {
    return axios.post(baseUrl() + `/purchase/search?page=${data.pageNumber}`, data.data);
  },

  fetchPurchaseDetailSearch(data) {
    return axios.post(baseUrl() + `/purchasedetails/search?page=${data.pageNumber}`, data.data);
  },

  fetchItemPurchaseGrouped(data) {
    return axios.post(
      baseUrl() + `/purchasedetails/item-grouped?page=${data.pageNumber}`,
      data.data
    );
  },
};
