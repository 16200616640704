<template>
  <v-list-item class="pa-0" dense>
    <v-list-item-content class="py-0 px-2">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on" class="menu-button pa-0 rounded-xl">
            <div class="avatar-container">
              <v-avatar color="brown" size="48">
                <span class="white--text text-h6"> {{ initials }} </span>
              </v-avatar>
              <v-badge
                :color="getOnlineStatus ? 'green' : 'red'"
                overlap
                dot
                class="badge-outside"
                :style="getLanguage ? 'right: 0' : 'left: 0'"
              ></v-badge>
            </div>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold"> {{ getUser.name }} </v-list-item-title>
              <v-list-item-subtitle>
                {{ $t("database") }} : {{ getDatabase }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>

        <!-- Menu Options -->
        <v-card>
          <v-list-item-content class="justify-center py-2">
            <div class="mx-auto text-center">
              <v-btn
                small
                text
                to="/userprofile"
                class="d-flex align-center justify-space-between px-2"
              >
                <v-icon>mdi-account-box-outline</v-icon>
                {{ $t("userprofile") }}
              </v-btn>

              <v-divider class="my-1"></v-divider>
              <v-btn
                small
                text
                class="d-flex align-center justify-space-between px-2 w-100"
                @click="reloadUser()"
              >
                <v-icon>mdi-reload</v-icon>
                {{ $t("reloaduser") }}
              </v-btn>

              <v-divider class="my-1"></v-divider>
              <v-btn
                small
                text
                to="/changedatabase"
                class="d-flex align-center justify-space-between px-2"
              >
                <v-icon>mdi-database-sync-outline</v-icon>
                {{ $t("changedatabase") }}
              </v-btn>

              <v-divider v-if="getUser && getUser.role.id == 1" class="my-1"></v-divider>
              <v-btn
                v-if="getUser && getUser.role.id == 1"
                small
                text
                class="d-flex align-center justify-space-between px-2 w-100"
                @click="downloadDB()"
                :loading="!isUserControlLoaded"
                :disabled="!isUserControlLoaded"
              >
                <v-icon>mdi-download-box-outline</v-icon>
                Download DB
              </v-btn>

              <v-divider class="my-1"></v-divider>
              <v-btn
                small
                text
                class="d-flex align-center justify-space-between px-2 w-100"
                @click="accountLogout()"
              >
                <v-icon>mdi-logout</v-icon>
                {{ $t("auth.logout") }}
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>

      <!-- <v-list-item-title class="text-h6 text-center pa-1">
        <v-badge class="ma-0 w-100" :color="getOnlineStatus ? 'green' : 'red'" overlap dot>
          <v-menu bottom min-width="220px" rounded offset-y>
            <template v-slot:activator="{ on }">
              <div v-on="on" class="d-flex">
                <v-btn icon x-large>
                  <v-avatar color="brown" size="48">
                    <span class="white--text text-h6"> {{ initials }} </span>
                  </v-avatar>
                </v-btn>
                <div>
                  <h6 class="pt-1">{{ getUser.name }}</h6>
                  <h6 class="pt-1">{{ $t("database") }} : {{ getDatabase }}</h6>
                </div>
              </div>
            </template>
            <v-card>
              <v-list-item-content class="justify-center py-2">
                <div class="mx-auto text-center">
                  <div class="pa-1">
                    {{ getUser.name }}
                  </div>

                  <v-divider class="my-1"></v-divider>
                  <v-btn
                    small
                    text
                    to="/userprofile"
                    class="d-flex align-center justify-space-between px-2"
                  >
                    <v-icon>mdi-account-box-outline</v-icon>
                    {{ $t("userprofile") }}
                  </v-btn>

                  <v-divider class="my-1"></v-divider>
                  <v-btn
                    small
                    text
                    class="d-flex align-center justify-space-between px-2 w-100"
                    @click="reloadUser()"
                  >
                    <v-icon>mdi-reload</v-icon>
                    {{ $t("reloaduser") }}
                  </v-btn>

                  <v-divider class="my-1"></v-divider>
                  <v-btn
                    small
                    text
                    to="/changedatabase"
                    class="d-flex align-center justify-space-between px-2"
                  >
                    <v-icon>mdi-database-sync-outline</v-icon>
                    {{ $t("changedatabase") }}
                  </v-btn>

                  <v-divider v-if="getUser && getUser.role.id == 1" class="my-1"></v-divider>
                  <v-btn
                    v-if="getUser && getUser.role.id == 1"
                    small
                    text
                    class="d-flex align-center justify-space-between px-2 w-100"
                    @click="downloadDB()"
                    :loading="!isUserControlLoaded"
                    :disabled="!isUserControlLoaded"
                  >
                    <v-icon>mdi-download-box-outline</v-icon>
                    Download DB
                  </v-btn>

                  <v-divider class="my-1"></v-divider>
                  <v-btn
                    small
                    text
                    class="d-flex align-center justify-space-between px-2 w-100"
                    @click="accountLogout()"
                  >
                    <v-icon>mdi-logout</v-icon>
                    {{ $t("auth.logout") }}
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-card>
          </v-menu>
        </v-badge>
      </v-list-item-title> -->
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["getOnlineStatus"],
  data: () => ({}),
  components: {},
  methods: {
    ...mapActions("UsersController", ["downloadDB"]),
    ...mapActions("User", ["reloadUser"]),

    accountLogout() {
      this.$emit("accountLogout");
    },
  },
  computed: {
    ...mapGetters("UsersController", ["isUserControlLoaded"]),
    ...mapGetters("Company", ["getDatabase"]),
    initials() {
      return (
        this.getUser?.name
          .split(" ")
          .filter((name) => name.length > 0)
          .reduce(
            (initials, name, index) => initials + (index < 2 ? name[0].toUpperCase() : ""),
            ""
          ) ?? "Name"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-container {
  position: relative;
  display: inline-block;
}

.badge-outside {
  position: absolute;
  top: 0;
}
.menu-button::before {
  // background: red;
  border-radius: 24px;
}
</style>
