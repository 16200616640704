import { EntryAPI } from "@/helpers/Apis/Entries/Entries";
import i18n from "@/plugins/i18n/i18n";

const state = {
  errors: [],
  isDrawerActionLoaded: true,
};

const getters = {
  isDrawerActionLoaded(state) {
    return state.isDrawerActionLoaded;
  },

  getDrawerActionErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsDrawerActionLoaded: (state, payload) => {
    state.isDrawerActionLoaded = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },
};

const actions = {
  drawerWithdraw: ({ commit }, data) => {
    commit("setIsDrawerActionLoaded", false);
    return new Promise((resolve, reject) => {
      EntryAPI.drawerWithdraw(data)
        .then((res) => {
          commit("setIsDrawerActionLoaded", true);
          commit("setErrors", []);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsDrawerActionLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  drawerDeposit: ({ commit }, data) => {
    commit("setIsDrawerActionLoaded", false);
    return new Promise((resolve, reject) => {
      EntryAPI.drawerDeposit(data)
        .then((res) => {
          commit("setIsDrawerActionLoaded", true);
          commit("setErrors", []);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsDrawerActionLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  drawerCashTransfer: ({ commit }, data) => {
    commit("setIsDrawerActionLoaded", false);
    return new Promise((resolve, reject) => {
      EntryAPI.drawerCashTransfer(data)
        .then((res) => {
          commit("setIsDrawerActionLoaded", true);
          commit("setErrors", []);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsDrawerActionLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
