import { InventoryAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  restockInventory: [],
  isRestockInventoryLoaded: true,
  errors: [],
  currentPage: null,
};

const getters = {
  getRestockInventory(state) {
    return state.restockInventory;
  },
  isRestockInventoryLoaded(state) {
    return state.isRestockInventoryLoaded;
  },
  getCurrentPage(state) {
    return state.currentPage;
  },
};

const mutations = {
  setRestockInventory: (state, payload) => {
    state.restockInventory = payload;
  },
  setIsRestockInventoryLoaded: (state, payload) => {
    state.isRestockInventoryLoaded = payload;
  },
  setCurrentPage(state, payload) {
    state.restockInventory.meta.current_page = payload.value;
  },
};

const actions = {
  fetchRestockAlert: ({ commit }, data) => {
    commit("setIsRestockInventoryLoaded", false);
    return new Promise((resolve, reject) => {
      InventoryAPI.fetchRestockAlert(data)
        .then((res) => {
          commit("setIsRestockInventoryLoaded", true);
          commit("setRestockInventory", res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsRestockInventoryLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
