import {
  EntryReportAPI,
  SaleAPI,
  CashierAPI,
  SaleReturnAPI,
  PurchaseAPI,
  PurchaseReturnAPI,
  StoreInAPI,
  StoreOutAPI,
  TransferAPI,
  MaintenanceAPI,
} from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  reportData: {},
  errors: [],
  isReportDataLoaded: true,
};

const getters = {
  isReportDataLoaded(state) {
    return state.isReportDataLoaded;
  },
  getOGReportData(state) {
    return state.reportData.data;
  },
  getReportData(state) {
    // let data = JSON.parse(JSON.stringify(state.reportData));
    // // show invoice details only in the last entry
    // const uniqueInvoices = {};
    // for (let i = data.length - 1; i >= 0; i--) {
    //   const obj = data[i];
    //   const key = `${obj.invoice?.id}-${obj.invoice?.invoicetype_id}`;
    //   obj.index = i;
    //   // obj.hightlight = obj.invoice?.id % 2 === 0 ? "highlight" : "";

    //   if (!uniqueInvoices[key]) {
    //     uniqueInvoices[key] = true;
    //   } else {
    //     obj.invoice = null;
    //   }
    // }
    // return data;
    return state.reportData;
  },
  getReportDataErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsReportDataLoaded: (state, payload) => {
    state.isReportDataLoaded = payload;
  },
  setReportData: (state, payload) => {
    state.reportData = payload;
  },
  setDetailData: (state, payload) => {
    let index = payload.index;
    state.reportData[index].details = payload.data;
  },
  setIsDetailLoading: (state, payload) => {
    let index = payload.index;
    state.reportData[index].detailsLoaded = payload.data;
  },
  setCurrentPage(state, payload) {
    state.reportData.meta.current_page = payload.value;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },

  delRow: (state, paylaod) => {
    let entries = state.reportData.data;
    let detail = paylaod;
    let entriesToDelete = entries.filter(
      (object) =>
        object.id === detail.id ||
        object.related_detail_id === detail.id ||
        object.id === detail.id ||
        object.id === detail.related_detail_id
    );
    entriesToDelete.forEach((entry) => {
      let index = entries.indexOf(entry);
      entries.splice(index, 1);
    });
  },
};

const actions = {
  reportData: ({ commit }, data) => {
    commit("setIsReportDataLoaded", false);
    return new Promise((resolve, reject) => {
      EntryReportAPI.reportData(data)
        .then((res) => {
          commit("setReportData", res.data);
          commit("setIsReportDataLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsReportDataLoaded", true);
          // let nameError = Object.values(error.response.data.errors).flat();
          // commit("setErrors", nameError[0]);
          reject(error);
        });
    });
  },

  cashierDetails: ({ commit }, data) => {
    commit("setIsReportDataLoaded", false);
    return new Promise((resolve, reject) => {
      CashierAPI.cashierDetails(data.invoice_id)
        .then((res) => {
          resolve(res);
          commit("setDetailData", { data: res.data.data, index: data.index });
          commit("setIsReportDataLoaded", true);
        })
        .catch((error) => {
          reject(error);
          commit("setIsReportDataLoaded", true);
          let nameError = [];
          if (error.response.data.errors) {
            nameError = Object.values(error.response.data.errors).flat();
            commit("setErrors", nameError);
          } else if (error.response.data.message) {
            commit("setErrors", [error.response.data.message]);
          }
        });
    });
  },

  purchaseDetails: ({ commit }, data) => {
    commit("setIsReportDataLoaded", false);
    return new Promise((resolve, reject) => {
      PurchaseAPI.purchaseDetails(data.invoice_id)
        .then((response) => {
          resolve(response);
          commit("setDetailData", { data: response.data.data, index: data.index });
          commit("setIsReportDataLoaded", true);
        })
        .catch((error) => {
          reject(error);
          commit("setIsReportDataLoaded", true);
          let nameError = [];
          if (error.response.data.errors) {
            nameError = Object.values(error.response.data.errors).flat();
            commit("setErrors", nameError);
          } else if (error.response.data.message) {
            commit("setErrors", [error.response.data.message]);
          }
        });
    });
  },

  saleDetails: ({ commit }, data) => {
    commit("setIsReportDataLoaded", false);
    return new Promise((resolve, reject) => {
      SaleAPI.saleDetails(data.invoice_id)
        .then((response) => {
          resolve(response);
          commit("setDetailData", { data: response.data.data, index: data.index });
          commit("setIsReportDataLoaded", true);
        })
        .catch((error) => {
          reject(error);
          commit("setIsReportDataLoaded", true);
          let nameError = [];
          if (error.response.data.errors) {
            nameError = Object.values(error.response.data.errors).flat();
            commit("setErrors", nameError);
          } else if (error.response.data.message) {
            commit("setErrors", [error.response.data.message]);
          }
        });
    });
  },

  purchaseReturnDetails: ({ commit }, data) => {
    commit("setIsReportDataLoaded", false);
    return new Promise((resolve, reject) => {
      PurchaseReturnAPI.purchaseReturnDetails(data.invoice_id)
        .then((response) => {
          resolve(response);
          commit("setDetailData", { data: response.data.data, index: data.index });
          commit("setIsReportDataLoaded", true);
        })
        .catch((error) => {
          reject(error);
          commit("setIsReportDataLoaded", true);
          let nameError = [];
          if (error.response.data.errors) {
            nameError = Object.values(error.response.data.errors).flat();
            commit("setErrors", nameError);
          } else if (error.response.data.message) {
            commit("setErrors", [error.response.data.message]);
          }
        });
    });
  },

  saleReturnDetails: ({ commit }, data) => {
    commit("setIsReportDataLoaded", false);
    return new Promise((resolve, reject) => {
      SaleReturnAPI.saleReturnDetails(data.invoice_id)
        .then((response) => {
          resolve(response);
          commit("setDetailData", { data: response.data.data, index: data.index });
          commit("setIsReportDataLoaded", true);
        })
        .catch((error) => {
          reject(error);
          commit("setIsReportDataLoaded", true);
          let nameError = [];
          if (error.response.data.errors) {
            nameError = Object.values(error.response.data.errors).flat();
            commit("setErrors", nameError);
          } else if (error.response.data.message) {
            commit("setErrors", [error.response.data.message]);
          }
        });
    });
  },

  storeInDetails: ({ commit }, data) => {
    commit("setIsReportDataLoaded", false);
    return new Promise((resolve, reject) => {
      StoreInAPI.storeInDetails(data.invoice_id)
        .then((response) => {
          resolve(response);
          commit("setDetailData", { data: response.data.data, index: data.index });
          commit("setIsReportDataLoaded", true);
        })
        .catch((error) => {
          reject(error);
          commit("setIsReportDataLoaded", true);
          let nameError = [];
          if (error.response.data.errors) {
            nameError = Object.values(error.response.data.errors).flat();
            commit("setErrors", nameError);
          } else if (error.response.data.message) {
            commit("setErrors", [error.response.data.message]);
          }
        });
    });
  },

  storeOutDetails: ({ commit }, data) => {
    commit("setIsReportDataLoaded", false);
    return new Promise((resolve, reject) => {
      StoreOutAPI.storeOutDetails(data.invoice_id)
        .then((response) => {
          resolve(response);
          commit("setDetailData", { data: response.data.data, index: data.index });
          commit("setIsReportDataLoaded", true);
        })
        .catch((error) => {
          reject(error);
          commit("setIsReportDataLoaded", true);
          let nameError = [];
          if (error.response.data.errors) {
            nameError = Object.values(error.response.data.errors).flat();
            commit("setErrors", nameError);
          } else if (error.response.data.message) {
            commit("setErrors", [error.response.data.message]);
          }
        });
    });
  },

  transferDetails: ({ commit }, data) => {
    commit("setIsReportDataLoaded", false);
    return new Promise((resolve, reject) => {
      TransferAPI.transferDetails(data.invoice_id)
        .then((response) => {
          resolve(response);
          commit("setDetailData", { data: response.data.data, index: data.index });
          commit("setIsReportDataLoaded", true);
        })
        .catch((error) => {
          reject(error);
          commit("setIsReportDataLoaded", true);
          let nameError = [];
          if (error.response.data.errors) {
            nameError = Object.values(error.response.data.errors).flat();
            commit("setErrors", nameError);
          } else if (error.response.data.message) {
            commit("setErrors", [error.response.data.message]);
          }
        });
    });
  },

  maintenanceDetails: ({ commit }, data) => {
    commit("setIsReportDataLoaded", false);
    return new Promise((resolve, reject) => {
      MaintenanceAPI.maintenanceDetails(data.invoice_id)
        .then((response) => {
          resolve(response);
          commit("setDetailData", { data: response.data.data, index: data.index });
          commit("setIsReportDataLoaded", true);
        })
        .catch((error) => {
          reject(error);
          commit("setIsReportDataLoaded", true);
          let nameError = [];
          if (error.response.data.errors) {
            nameError = Object.values(error.response.data.errors).flat();
            commit("setErrors", nameError);
          } else if (error.response.data.message) {
            commit("setErrors", [error.response.data.message]);
          }
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
