import baseUrl from "@/constants/API";

export const RestaurantShiftAPI = {
  fetchRestaurantShifts() {
    return axios.get(baseUrl() + `/restaurant/shifts/get`);
  },
  createRestaurantShift(data) {
    return axios.post(baseUrl() + `/restaurant/shifts/create`, data);
  },

  updateRestaurantShift(data) {
    return axios.post(baseUrl() + `/restaurant/shifts/update/${data.id}`, data.data);
  },
  deleteRestaurantShift(id) {
    return axios.delete(baseUrl() + `/restaurant/shifts/delete/${id}`);
  },
};
