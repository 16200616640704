import { BranchAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  branches: [],
  errors: [],
  isBranchesLoaded: false,
};

const getters = {
  isBranchesLoaded(state) {
    return state.isBranchesLoaded;
  },
  getBranches(state) {
    return state.branches;
  },
  getBranchErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsBranchesLoaded: (state, payload) => {
    state.isBranchesLoaded = payload;
  },
  setBranches: (state, payload) => {
    state.branches = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setNewUpdateBranch(state, payload) {
    let branchIndex = state.branches.findIndex((o) => o.id === payload.id);
    if (branchIndex !== -1) {
      // state.branches[branchIndex] = payload;
      state.branches.splice(branchIndex, 1, { ...payload });
    } else {
      state.branches.unshift({ ...payload });
    }

    ///////////
    let user = JSON.parse(localStorage.getItem("user"));
    let userBranches = user.user_settings?.access_control?.branches;
    if (userBranches && userBranches.length > 0) {
      // has selected branches then dont do anything
      return;
    }

    user.user_settings = user.user_settings || {};
    user.user_settings.access_control = user.user_settings.access_control || {};
    user.user_settings.access_control.branches = user.user_settings.access_control.branches || [];

    const userBranchIndex = user.branches.findIndex((o) => o.id === payload.id);
    const branchObject = {
      id: payload.id,
      name: payload.name,
    };
    if (userBranchIndex !== -1) {
      user.branches[userBranchIndex] = branchObject;
    } else {
      user.branches.push(branchObject);
    }
    user.user_settings.access_control.branches.push(payload.id);
    // Save updated user object back to localStorage
    localStorage.setItem("user", JSON.stringify(user));
  },
  deleteBranch(state, payload) {
    let branchIndex = state.branches.findIndex((o) => o.id === payload);
    if (branchIndex !== -1) {
      state.branches.splice(branchIndex, 1);
    }

    ///
    let user = JSON.parse(localStorage.getItem("user")) || {};
    // Ensure necessary properties exist
    user.user_settings = user.user_settings || {};
    user.user_settings.access_control = user.user_settings.access_control || {};
    user.user_settings.access_control.branches = user.user_settings.access_control.branches || [];
    user.branches = user.branches || [];
    // Remove the branch by id
    const branchIdToRemove = payload;
    // Remove from user.branches
    user.branches = user.branches.filter((branch) => branch.id !== branchIdToRemove);

    // Remove from user_settings.access_control.branches
    user.user_settings.access_control.branches = user.user_settings.access_control.branches.filter(
      (id) => id !== branchIdToRemove
    );
    // Save updated user object back to localStorage
    localStorage.setItem("user", JSON.stringify(user));
  },
};

const actions = {
  fetchBranches: ({ commit }) => {
    commit("setIsBranchesLoaded", false);
    return new Promise((resolve, reject) => {
      BranchAPI.fetchBranches()
        .then((res) => {
          commit("setBranches", res.data.data);
          commit("setIsBranchesLoaded", true);
          resolve(res.data.data);
        })
        .catch((error) => {
          commit("setIsBranchesLoaded", true);
          reject(error);
        });
    });
  },

  createBranch: ({ commit }, data) => {
    commit("setIsBranchesLoaded", false);
    return new Promise((resolve, reject) => {
      BranchAPI.createBranch(data)
        .then((res) => {
          commit("setIsBranchesLoaded", true);
          commit("setErrors", []);
          commit("setNewUpdateBranch", res.data.data);
          commit("User/refreshUserLocal", null, { root: true });
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsBranchesLoaded", true);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updateBranch: ({ commit }, data) => {
    commit("setIsBranchesLoaded", false);
    return new Promise((resolve, reject) => {
      BranchAPI.updateBranch(data)
        .then((res) => {
          commit("setIsBranchesLoaded", true);
          commit("setErrors", []);
          commit("setNewUpdateBranch", res.data.data);
          commit("User/refreshUserLocal", null, { root: true });
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsBranchesLoaded", true);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  deleteBranch: ({ commit }, id) => {
    commit("setIsBranchesLoaded", false);
    return new Promise((resolve, reject) => {
      BranchAPI.deleteBranch(id)
        .then((res) => {
          commit("setIsBranchesLoaded", true);
          commit("setErrors", []);

          commit("deleteBranch", id);
          // commit("User/deleteAccessControl", { data: id, type: "branches" }, { root: true });
          commit("User/refreshUserLocal", null, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsBranchesLoaded", true);
          reject(error);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  fetchBasicBranches: ({ commit }) => {
    commit("setIsBranchesLoaded", false);
    return new Promise((resolve, reject) => {
      BranchAPI.fetchBasicBranches()
        .then((res) => {
          commit("setBranches", res.data);
          commit("setIsBranchesLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsBranchesLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
