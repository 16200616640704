import i18n from "@/plugins/i18n/i18n";

const StatusesArray = [
  { id: 1, name: i18n.t("placed") },
  { id: 2, name: i18n.t("preparing") },
  { id: 3, name: i18n.t("finished") },
  { id: 4, name: i18n.t("completed") },
  { id: 5, name: i18n.t("canceled") },
  { id: 6, name: i18n.t("delivering") },
];

const Statuses = {
  Placed: { id: 1, name: i18n.t("placed") },
  Preparing: { id: 2, name: i18n.t("preparing") },
  Finished: { id: 3, name: i18n.t("finished") },
  Completed: { id: 4, name: i18n.t("completed") },
  Canceled: { id: 5, name: i18n.t("canceled") },
  Delivering: { id: 6, name: i18n.t("delivering") },
};

const Types = [
  { id: 1, name: i18n.t("delivery") },
  { id: 2, name: i18n.t("takeaway") },
  { id: 3, name: i18n.t("dinein") },
];
const PayTypes = [
  { id: 1, name: i18n.t("cash") },
  { id: 2, name: i18n.t("open") },
  { id: 3, name: i18n.t("visa") },
];

const ReservationStatusesArray = [
  { id: 1, name: i18n.t("pending") },
  { id: 2, name: i18n.t("confirmed") },
  { id: 3, name: i18n.t("canceled") },
  { id: 4, name: i18n.t("completed") },
];

const ReservationStatuses = {
  Pending: { id: 1, name: i18n.t("pending") },
  Confirmed: { id: 2, name: i18n.t("confirmed") },
  Canceled: { id: 3, name: i18n.t("canceled") },
  Completed: { id: 4, name: i18n.t("completed") },
};

const CategoryIcons = [
  "mdi-baguette",
  "mdi-blender",
  "mdi-bottle-soda-classic",
  "mdi-bowl-mix",
  "mdi-bread-slice",
  "mdi-cupcake",
  "mdi-cake-variant",
  "mdi-candy",
  "mdi-coffee",
  "mdi-cookie",
  "mdi-cup",
  "mdi-food",
  "mdi-food-apple",
  "mdi-food-fork-drink",
  "mdi-french-fries",
  "mdi-food-drumstick",
  "mdi-food-steak",
  "mdi-food-hot-dog",
  "mdi-food-turkey",
  "mdi-food-croissant",
  "mdi-fish",
  "mdi-hamburger",
  "mdi-rice",
  "mdi-liquor",
  "mdi-ice-cream",
  "mdi-ice-pop",
  "mdi-leaf",
  "mdi-carrot",
  "mdi-noodles",
  "mdi-pasta",
  "mdi-pizza",
  "mdi-cheese",
  "mdi-grill",
  "mdi-grill-outline",
  "mdi-star",
];

export {
  StatusesArray,
  Statuses,
  Types,
  PayTypes,
  ReservationStatusesArray,
  ReservationStatuses,
  CategoryIcons,
};
