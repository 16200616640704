import baseUrl from "@/constants/API";

export const SaleReportAPI = {
  fetchSaleSearch(data) {
    return axios.post(baseUrl() + `/sale/search?page=${data.pageNumber}`, data.data);
  },

  fetchSaleDetailSearch(data) {
    return axios.post(baseUrl() + `/saledetails/search?page=${data.pageNumber}`, data.data);
  },

  // fetchStagnantItems(data) {
  //   return axios.post(baseUrl() + `/sale/stagnantitems/search?page=${data.pageNumber}`, data.data);
  // },
  fetchItemSaleGrouped(data) {
    return axios.post(baseUrl() + `/saledetails/item-grouped?page=${data.pageNumber}`, data.data);
  },
};
