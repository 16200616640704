import { ItemSerialAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  serials: [],
  isDataLoaded: true,
  errors: [],
};

const getters = {
  getSerials(state) {
    return state.serials;
  },
  getIsSerialsLoaded(state) {
    return state.isDataLoaded;
  },
  getErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setSerials: (state, payload) => {
    state.serials = payload;
  },

  setIsSerialsLoaded(state, payload) {
    state.isDataLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setCurrentPage(state, payload) {
    state.serials.meta.current_page = payload.value;
  },

  deleteSerial(state, payload) {
    let itemIndex = state.serials.data.findIndex((o) => o.id === payload);
    if (itemIndex !== -1) {
      state.serials.data.splice(itemIndex, 1);
    }
  },
  updateSerial(state, payload) {
    // let itemIndex = state.items.data.findIndex((o) => o.id === payload.id);
    // state.items.data.splice(itemIndex, 1, { ...payload });
    let serial = state.serials.data.find((serial) => serial.id === payload.id);
    serial.serial = payload.serial;
  },
};

const actions = {
  createSerial: ({ commit }, data) => {
    commit("setIsSerialsLoaded", false);
    return new Promise((resolve, reject) => {
      ItemSerialAPI.createSerial(data)
        .then((res) => {
          commit("setIsSerialsLoaded", true);
          commit("setErrors", []);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsSerialsLoaded", true);
          let errors = Object.values(error.response.data.errors).flat().flat();
          commit("setErrors", errors);
          reject(error);
        });
    });
  },
  editSerial: ({ commit }, data) => {
    commit("setIsSerialsLoaded", false);
    return new Promise((resolve, reject) => {
      ItemSerialAPI.editSerial(data)
        .then((res) => {
          commit("setIsSerialsLoaded", true);
          commit("setErrors", []);
          commit("updateSerial", res.data.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsSerialsLoaded", true);
          let errors = Object.values(error.response.data.errors).flat().flat();
          commit("setErrors", errors);
          reject(error);
        });
    });
  },
  fetchSerialsFilter: ({ commit }, data) => {
    commit("setIsSerialsLoaded", false);
    return new Promise((resolve, reject) => {
      ItemSerialAPI.fetchSerialsFilter(data)
        .then((res) => {
          commit("setIsSerialsLoaded", true);
          commit("setSerials", res.data);
          commit("setErrors", []);
          resolve(res.data.data);
        })
        .catch((error) => {
          commit("setIsSerialsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  deleteSerial: ({ commit }, id) => {
    commit("setIsSerialsLoaded", false);
    return new Promise((resolve, reject) => {
      ItemSerialAPI.deleteSerial(id)
        .then((res) => {
          commit("setErrors", []);
          commit("setIsSerialsLoaded", true);

          commit("deleteSerial", id);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsSerialsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  ReviewSerials: ({ commit }, data) => {
    commit("App/setAppLoader", true, { root: true });
    commit("setIsSerialsLoaded", false);
    return new Promise((resolve, reject) => {
      ItemSerialAPI.ReviewSerials(data)
        .then((response) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsSerialsLoaded", true);
          resolve(response.data);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsSerialsLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
