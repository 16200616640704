<template>
  <v-tooltip top color="toolTipBgColor">
    <template v-slot:activator="{ on, attrs }">
      <!-- v-if="isBtn" -->
      <!-- small -->
      <router-link
        v-if="isLink"
        :to="{ path: link, query: linkQuery }"
        class="linkClass d-flex align-center justify-center"
      >
        <v-btn
          v-if="isBtn"
          :class="customClass"
          :color="btnColor"
          :loading="loading"
          :disabled="disabled"
          v-on="on"
          v-bind="attrs"
          :small="isSmallBtn"
        >
          <v-icon>
            {{ icon }}
          </v-icon>
        </v-btn>

        <v-icon v-else v-on="on" v-bind="attrs" :color="iconColor">
          {{ icon }}
        </v-icon>
      </router-link>

      <v-btn
        v-else
        :min-width="minWidth"
        :icon="!isBtn"
        :class="customClass"
        :color="btnColor"
        :disabled="disabled"
        @click="clickedIcon"
        v-bind="attrs"
        v-on="on"
        :loading="loading"
        :small="isSmallBtn"
      >
        <v-icon :color="iconColor"> {{ icon }} </v-icon>
      </v-btn>

      <!-- <v-icon
        v-else
        class="mx-1"
        fab
        v-bind="attrs"
        v-on="on"
        :small="!isBigIcon"
        @click="clickedIcon"
      >
        {{ icon }}
      </v-icon> -->
    </template>
    <span> {{ name }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: [
    "icon",
    "customClass",
    "name",
    "isBtn",
    "btnColor",
    "iconColor",
    "disabled",
    "loading",
    "isSmallBtn",
    "minWidth",
    "isLink",
    "link",
    "linkQuery",
  ],
  methods: {
    clickedIcon() {
      this.$emit("iconClicked");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.v-icon.v-icon::after {
  background-color: transparent !important; /* Remove the background color */
}
::v-deep.v-btn:before {
  background-color: transparent !important; /* Remove the background color */
}
.linkClass {
  text-decoration: none;
  color: inherit;
}
</style>
