// import { InvoiceTypeIds } from "@/helpers/Constants/General";
import { toFixedIfNecessary } from "@/helpers/HelperFunctions";
import { ProportionProductions } from "@/helpers/Apis/Productions/ProportionProductions";

import { EntryDetailAPI } from "@/helpers/Apis/Entries/EntryDetails";
import i18n from "@/plugins/i18n/i18n";
import router from "@/router";

const state = {
  production: {},

  errors: [],
  productionDetailsErrors: [],
  isProductionLoaded: true,
  isEntriesLoaded: true,
};

const getters = {
  getProduction(state) {
    return state.production;
  },

  isProductionLoaded(state) {
    return state.isProductionLoaded;
  },

  getProductionErrors(state) {
    return state.errors;
  },

  getProductionDetailsErrors(state) {
    return state.productionDetailsErrors;
  },

  isEntriesLoaded(state) {
    return state.isEntriesLoaded;
  },
};

const mutations = {
  setProduction: (state, payload) => {
    state.production = payload;
  },

  setIsProductionLoaded: (state, payload) => {
    state.isProductionLoaded = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setExtraEntries(state, payload) {
    state.production.invoice_entries_extra = payload;
  },

  setNewDetailPrices(state, payload) {
    let details = state.production.invoice_details;
    payload.forEach((el) => {
      let detailIndex = details.findIndex((o) => {
        return o.id === el.id;
      });
      state.production.invoice_details[detailIndex].price = el.price;
      state.production.invoice_details[detailIndex].unit_price = el.unit_price;
      state.production.invoice_details[detailIndex].piece_price = el.piece_price;
      state.production.invoice_details[detailIndex].total = el.total;
    });
  },

  addProductionDetail(state, payload) {
    let combinedEntries = [
      ...payload.entries,
      ...payload.related_details.flatMap((detail) => detail.entries),
    ];

    state.production.invoice_entries = [...state.production.invoice_entries, ...combinedEntries];
    state.production.invoice_details.push(payload);
  },

  deleteProductionDetail(state, payload) {
    let detailIndex = state.production?.invoice_details?.findIndex((o) => o.id === payload);
    // Check if drawer is found
    if (detailIndex !== -1) {
      state.production?.invoice_details?.splice(detailIndex, 1); // Remove drawer
    }
  },

  setIsEntriesLoaded(state, payload) {
    state.isEntriesLoaded = payload;
  },
};

const actions = {
  fetchProportionProduction: ({ commit }, id) => {
    commit("setIsProductionLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      ProportionProductions.fetchProportionProduction(id)
        .then((res) => {
          commit("setProduction", res.data.data);
          commit("setIsProductionLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsProductionLoaded", true);
          reject(error);
        });
    });
  },
  previousInvoice: ({ commit }, id) => {
    commit("setIsProductionLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      ProportionProductions.previousProportionProduction(id)
        .then((res) => {
          commit("setProduction", res.data.data);
          commit("setIsProductionLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", []);
          router.push({
            path: `/productions/proportion/${res.data.data.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsProductionLoaded", true);
          reject(error);
        });
    });
  },
  nextInvoice: ({ commit }, id) => {
    commit("setIsProductionLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      ProportionProductions.nextProportionProduction(id)
        .then((res) => {
          commit("setProduction", res.data.data);
          commit("setIsProductionLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", []);
          router.push({
            path: `/productions/proportion/${res.data.data.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsProductionLoaded", true);
          reject(error);
        });
    });
  },

  createProportionProduction: ({ commit }, data) => {
    commit("setIsProductionLoaded", false);
    return new Promise((resolve, reject) => {
      ProportionProductions.createProportionProduction(data)
        .then((res) => {
          commit("setIsProductionLoaded", true);
          commit("setErrors", []);

          let invoice = res.data.data;
          commit("setProduction", invoice);
          router.push({
            path: `/productions/proportion/${invoice.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsProductionLoaded", true);
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
  updateProportionProduction: ({ commit }, data) => {
    commit("setIsProductionLoaded", false);
    return new Promise((resolve, reject) => {
      ProportionProductions.updateProportionProduction(data)
        .then((res) => {
          commit("setIsProductionLoaded", true);
          commit("setErrors", []);
          commit("setProduction", res.data.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsProductionLoaded", true);
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
  createProductDetails: ({ commit }, data) => {
    commit("setIsProductionLoaded", false);
    return new Promise((resolve, reject) => {
      ProportionProductions.createProductDetails(data)
        .then((res) => {
          commit("setIsProductionLoaded", true);
          commit("addProductionDetail", res.data.data);
          commit("setErrors", []);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsProductionLoaded", true);
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  // createProportionProduction: ({ commit }, data) => {
  //   commit("setIsProductionLoaded", false);
  //   commit("App/setAppLoader", true, { root: true });
  //   return new Promise((resolve, reject) => {
  //     ProportionProductions.createProportionProduction(data)
  //       .then((res) => {
  //         commit("setIsProductionLoaded", true);
  //         commit("App/setAppLoader", false, { root: true });
  //         commit("setErrors", []);
  //         resolve(res);
  //       })
  //       .catch((error) => {
  //         commit("setIsProductionLoaded", true);
  //         commit("App/setAppLoader", false, { root: true });
  //         reject(error);

  //         let nameError = error.response.data.errors
  //           ? Object.values(error.response.data.errors).flat()
  //           : [error.response.data.message];
  //         commit("setErrors", nameError);
  //       });
  //   });
  // },

  // updateProportionProduction: ({ commit }, data) => {
  //   commit("setIsProductionLoaded", false);
  //   commit("App/setAppLoader", true, { root: true });
  //   return new Promise((resolve, reject) => {
  //     ProportionProductions.updateProportionProduction(data)
  //       .then((res) => {
  //         commit("setIsProductionLoaded", true);
  //         commit("App/setAppLoader", false, { root: true });

  //         commit("setErrors", []);
  //         resolve(res);
  //       })
  //       .catch((error) => {
  //         commit("setIsProductionLoaded", true);
  //         commit("App/setAppLoader", false, { root: true });
  //         reject(error);

  //         let nameError = error.response.data.errors
  //           ? Object.values(error.response.data.errors).flat()
  //           : [error.response.data.message];
  //         commit("setErrors", nameError);
  //       });
  //   });
  // },
  deleteProduction: ({ commit }, id) => {
    commit("setIsProductionLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      ProportionProductions.deleteProduction(id)
        .then((res) => {
          commit("setIsProductionLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", []);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsProductionLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  deleteProductionDetail: ({ commit }, id) => {
    commit("setIsProductionLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      ProportionProductions.deleteProductionDetail(id)
        .then((res) => {
          commit("setIsProductionLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", []);
          commit("deleteProductionDetail", id);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsProductionLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  createProdEntry: ({ state, commit }, data) => {
    commit("setIsEntriesLoaded", false);
    let invoiceData = state.production;
    let mergedData = { ...data, invoiceData: invoiceData };
    return new Promise((resolve, reject) => {
      EntryDetailAPI.createProdEntry(mergedData)
        .then((res) => {
          commit("setIsEntriesLoaded", true);
          commit("setErrors", []);
          let resultData = res.data.data;
          state.production.expenses = resultData.invoice.expenses;
          commit("setNewDetailPrices", resultData.details);
          resolve(resultData);
        })
        .catch((error) => {
          commit("setIsProductionLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  deleteProdEntry: ({ state, commit }, id) => {
    commit("setIsEntriesLoaded", false);
    return new Promise((resolve, reject) => {
      EntryDetailAPI.deleteProdEntry(id)
        .then((res) => {
          commit("setIsEntriesLoaded", true);
          commit("setErrors", []);
          let resultData = res.data.data;
          state.production.expenses = resultData.invoice.expenses;
          commit("setNewDetailPrices", resultData.details);
          resolve(resultData);
        })
        .catch((error) => {
          commit("setIsEntriesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
