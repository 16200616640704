import i18n from "@/plugins/i18n/i18n";

const PriceTypes = [
  {
    id: 1,
    name: i18n.t("consumer"),
  },
  {
    id: 2,
    name: i18n.t("commercial"),
  },
  {
    id: 3,
    name: i18n.t("halfcommercial"),
  },
  {
    id: 4,
    name: i18n.t("whole"),
  },
  {
    id: 5,
    name: i18n.t("halfwhole"),
  },
  {
    id: 6,
    name: i18n.t("listprice"),
  },
];

const BalanceTypes = [
  {
    id: 0,
    name: i18n.t("all"),
  },
  {
    id: 1,
    name: i18n.t("hasbalance"),
  },
  {
    id: 2,
    name: i18n.t("hasnobalance"),
  },
];

const typeToPriceName = {
  1: "consumer",
  2: "commercial",
  3: "halfcommercial",
  4: "whole",
  5: "halfwhole",
};

const InvoiceTypes = [
  {
    id: 1,
    name: i18n.t("beginningstore"),
  },
  {
    id: 2,
    name: i18n.t("sales"),
  },
  {
    id: 3,
    name: i18n.t("purchases"),
  },
  {
    id: 4,
    name: i18n.t("salesreturns"),
  },
  {
    id: 5,
    name: i18n.t("purchasesreturns"),
  },
  {
    id: 6,
    name: i18n.t("transfer"),
  },
  {
    id: 7,
    name: i18n.t("cashier"),
  },
  {
    id: 8,
    name: i18n.t("storeout"),
  },
  {
    id: 9,
    name: i18n.t("storein"),
  },
  {
    id: 12,
    name: i18n.t("maintenance"),
  },
  // {
  //   id: 13,
  //   name: i18n.t("cleanninginvoice"),
  // },
  {
    id: 14,
    name: i18n.t("production"),
  },
  {
    id: 15,
    name: i18n.t("reservation"),
  },
  {
    id: 17,
    name: i18n.t("restaurant"),
  },
  // {
  //   id: 18, // restaurant addons
  //   name: i18n.t("restaurant"),
  // },
];

const EntryTypes = [
  {
    id: 16,
    name: i18n.t("entry"),
  },
  {
    id: 21,
    name: i18n.t("draweraction"),
  },
  {
    id: 1,
    name: i18n.t("beginningstore"),
  },
  {
    id: 2,
    name: i18n.t("sales"),
  },
  {
    id: 3,
    name: i18n.t("purchases"),
  },
  {
    id: 4,
    name: i18n.t("salesreturns"),
  },
  {
    id: 5,
    name: i18n.t("purchasesreturns"),
  },
  {
    id: 6,
    name: i18n.t("transfer"),
  },
  {
    id: 7,
    name: i18n.t("cashier"),
  },
  {
    id: 8,
    name: i18n.t("storeout"),
  },
  {
    id: 9,
    name: i18n.t("storein"),
  },
  {
    id: 12,
    name: i18n.t("maintenance"),
  },
  // {
  //   id: 13,
  //   name: i18n.t("cleanninginvoice"),
  // },
  {
    id: 14,
    name: i18n.t("production"),
  },
  {
    id: 15,
    name: i18n.t("reservation"),
  },
  {
    id: 16,
    name: i18n.t("entry"),
  },
  {
    id: 17,
    name: i18n.t("restaurant"),
  },
  // {
  //   id: 18, // restaurant addons
  //   name: i18n.t("restaurant"),
  // },
  {
    id: 19,
    name: i18n.t("saleorder"),
  },
  {
    id: 20,
    name: i18n.t("purchaseorder"),
  },
  {
    id: 22,
    name: i18n.t("depreciations"),
  },
];

const InvoiceUrlByTypeId = {
  1: null, // beginning
  2: "/sales",
  3: "/purchases",
  4: "/salesreturns",
  5: "/purchasesreturns",
  6: "/storetransfers",
  7: "/cashierdaily",
  8: "/storeouts",
  9: "/storeins",
  10: null, // payables
  11: null, //receivables
  12: "/maintenances",
  13: null, // cleanning
  14: { 1: "/productions/free", 2: "/productions/proportion" },
  15: "/reservations",
  16: "/entries",
  17: "/restaurant/orders",
  18: "/restaurant/orders", // restaurant addons
  // restaurtants 17 and 18
  19: "/saleorders",
  20: "/purchaseorders",
  21: "/entries",
  22: "/entries", //asset depreciations
};

const InvoiceTypeIds = {
  beginning: 1,
  sale: 2,
  purchase: 3,
  salereturn: 4,
  purchasereturn: 5,
  transfer: 6,
  cashier: 7,
  storeout: 8,
  storein: 9,

  payable: 10,
  receivable: 11,

  maintenance: 12,
  // cleanning: 13,
  production: 14,
  reservation: 15,
  entry: 16,
};

const maintenanceStatuses = [
  { id: 0, name: i18n.t("workingonit") },
  { id: 1, name: i18n.t("onhold") },
  { id: 2, name: i18n.t("done") },
];

const partTypes = {
  Clients: 1,
  Suppliers: 2,
  Employees: 3,
  Owners: 4,
  "Maintenance Clients": 5,
  "Cleanning Clients": 6,
};
// { id: 0, name: i18n.t("workingonit") },
// { id: 1, name: i18n.t("onhold") },
// { id: 2, name: i18n.t("done") },

export {
  BalanceTypes,
  PriceTypes,
  typeToPriceName,
  EntryTypes,
  InvoiceTypes,
  InvoiceTypeIds,
  InvoiceUrlByTypeId,
  maintenanceStatuses,
  partTypes,
};
