import { InventoryAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  inventory: [],
  isInventoryLoaded: true,
  errors: [],
  currentPage: null,
};

const getters = {
  getInventory(state) {
    return state.inventory;
  },
  isInventoryLoaded(state) {
    return state.isInventoryLoaded;
  },
  getCurrentPage(state) {
    return state.currentPage;
  },
};

const mutations = {
  setInventory: (state, payload) => {
    state.inventory = payload;
  },
  setIsInventoryLoaded: (state, payload) => {
    state.isInventoryLoaded = payload;
  },
  setCurrentPage(state, payload) {
    state.inventory.meta.current_page = payload.value;
  },
};

const actions = {
  fetchInventoryCount: ({ commit }, data) => {
    commit("setIsInventoryLoaded", false);
    return new Promise((resolve, reject) => {
      InventoryAPI.fetchInventoryCount(data)
        .then((res) => {
          commit("setIsInventoryLoaded", true);
          commit("setInventory", res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsInventoryLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
