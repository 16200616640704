// Importing requirement
import store from "@/store";
import i18n from "@/plugins/i18n/i18n";

/**
 * Redirect to home page if not logged in and trying to access a restricted page.
 *
 * @param next
 * @param router
 */
export default function permission(requiredPermissions, condition = "AND") {
  return function ({ next, router }) {
    const user = store.getters["User/getUser"];

    const permissionsChecker = (permissions, condition) => {
      const userPermissions = Array.isArray(user.permissions) ? user.permissions : [];
      if (condition === "AND") {
        // Check if user has all required permissions
        return permissions.every((permission) => userPermissions.includes(permission));
      } else if (condition === "OR") {
        // Check if user has at least one required permission
        return permissions.some((permission) => userPermissions.includes(permission));
      }
      return false; // Default fallback
    };

    const required = Array.isArray(requiredPermissions)
      ? requiredPermissions
      : [requiredPermissions];
    if (!permissionsChecker(required, condition)) {
      console.log("Permission Middleware: Permission denied.");
      iziToast.error({
        message: i18n.t("auth.insufficient_permissions"),
        type: "error",
      });
      return next({ name: "Home", replace: true });
    }

    return next();
  };
}
