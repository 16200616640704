import { SubAccountAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  subaccs: [],
  mainAccSelected: [],
  errors: [],
  isSubAccsLoaded: true,
};

const getters = {
  isSubAccsLoaded(state) {
    return state.isSubAccsLoaded;
  },
  getSubAccs(state) {
    return state.subaccs;
  },
  getSubAccErrors(state) {
    return state.errors;
  },

  getMainAccSelected(state) {
    return state.mainAccSelected;
  },
};

const mutations = {
  setIsSubAccsLoaded: (state, payload) => {
    state.isSubAccsLoaded = payload;
  },
  setSubAccs: (state, payload) => {
    state.subaccs = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setCurrentPage(state, payload) {
    state.subaccs.meta.current_page = payload.value;
  },
  setMainAccSelected: (state, payload) => {
    state.mainAccSelected = payload;
  },

  setNewUpdateSubAcc(state, payload) {
    let subAccIndex = state.subaccs.data.findIndex((o) => o.id == payload.id);
    if (subAccIndex !== -1) {
      // state.categories[categoryIndex] = payload;
      state.subaccs.data.splice(subAccIndex, 1, { ...payload });
    } else {
      state.subaccs.data.unshift(payload);
      state.subaccs.total_count += 1;
    }

    let SubAccsLength = state.subaccs.data.length;
    if (SubAccsLength > state.subaccs.meta.per_page) {
      state.subaccs.data.splice(SubAccsLength - 1, 1);
    }
  },
  deleteSubAcc(state, payload) {
    let subAccIndex = state.subaccs.data.findIndex((o) => o.id === payload);
    if (subAccIndex !== -1) {
      state.subaccs.data.splice(subAccIndex, 1);
    }
  },
};

const actions = {
  fetchSubAccs: ({ commit }, data) => {
    commit("setIsSubAccsLoaded", false);
    return new Promise((resolve, reject) => {
      SubAccountAPI.fetchSubAccs(data)
        .then((res) => {
          commit("setSubAccs", res.data);
          commit("setIsSubAccsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsSubAccsLoaded", true);
          reject(error);
        });
    });
  },

  createSubAcc: ({ state, commit }, data) => {
    commit("setIsSubAccsLoaded", false);
    return new Promise((resolve, reject) => {
      SubAccountAPI.createSubAcc(data)
        .then((res) => {
          commit("setErrors", []);

          if (state.subaccs.meta.current_page == 1) {
            commit("setNewUpdateSubAcc", res.data.data);
          }
          commit("setIsSubAccsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsSubAccsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updateSubAcc: ({ commit }, data) => {
    commit("setIsSubAccsLoaded", false);
    return new Promise((resolve, reject) => {
      SubAccountAPI.updateSubAcc(data)
        .then((res) => {
          commit("setErrors", []);
          commit("setNewUpdateSubAcc", res.data.data);
          commit("setIsSubAccsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsSubAccsLoaded", true);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  deleteSubAcc: ({ commit }, id) => {
    commit("setIsSubAccsLoaded", false);
    return new Promise((resolve, reject) => {
      SubAccountAPI.deleteSubAcc(id)
        .then((res) => {
          commit("setErrors", []);
          commit("deleteSubAcc", id);
          commit("setIsSubAccsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsSubAccsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  fetchSubAccBalance: ({ commit }, id) => {
    commit("setIsSubAccsLoaded", false);
    return new Promise((resolve, reject) => {
      SubAccountAPI.fetchSubAccBalance(id)
        .then((res) => {
          commit("setErrors", []);
          commit("setIsSubAccsLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsSubAccsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  fetchMainAccSelectedSum: ({ commit }, data) => {
    commit("setIsSubAccsLoaded", false);
    return new Promise((resolve, reject) => {
      SubAccountAPI.fetchMainAccSelectedSum(data)
        .then((response) => {
          resolve(response);
          commit("setMainAccSelected", response.data.data);
          commit("setIsSubAccsLoaded", true);
        })
        .catch((error) => {
          commit("setIsSubAccsLoaded", true);
          reject(error);
        });
    });
  },
  fetchMainAccSelectedBasic: ({ commit }, data) => {
    commit("setIsSubAccsLoaded", false);
    return new Promise((resolve, reject) => {
      SubAccountAPI.fetchMainAccSelectedBasic(data)
        .then((response) => {
          resolve(response);
          commit("setMainAccSelected", response.data.data);
          commit("setIsSubAccsLoaded", true);
        })
        .catch((error) => {
          commit("setIsSubAccsLoaded", true);
          reject(error);
        });
    });
  },

  fetchBasicSubAccPaginate: ({ commit }, data) => {
    commit("setIsSubAccsLoaded", false);
    return new Promise((resolve, reject) => {
      SubAccountAPI.fetchBasicSubAccPaginate(data)
        .then((res) => {
          resolve(res);
          commit("setIsSubAccsLoaded", true);
        })
        .catch((error) => {
          commit("setIsSubAccsLoaded", true);
          reject(error);
        });
    });
  },
  fetchSubAccByMainAccSumPaginate: ({ commit }, data) => {
    commit("setIsSubAccsLoaded", false);
    return new Promise((resolve, reject) => {
      SubAccountAPI.fetchSubAccByMainAccSumPaginate(data)
        .then((res) => {
          resolve(res);
          commit("setIsSubAccsLoaded", true);
        })
        .catch((error) => {
          commit("setIsSubAccsLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
