import { ItemAPI } from "@/helpers/Apis/Item/Items";
import i18n from "@/plugins/i18n/i18n";

const state = {
  item: {},
  items: [],
  errors: [],
  isItemsLoaded: true,

  currentPage: null,
};

const getters = {
  isItemsLoaded(state) {
    return state.isItemsLoaded;
  },
  getItems(state) {
    return state.items;
  },
  getItem(state) {
    return state.item;
  },
  getItemErrors(state) {
    return state.errors;
  },

  getCurrentPage(state) {
    return state.currentPage;
  },
};

const mutations = {
  setIsItemsLoaded: (state, payload) => {
    state.isItemsLoaded = payload;
  },

  setItems: (state, payload) => {
    state.items = payload;
  },
  setItem: (state, payload) => {
    state.item = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setCurrentPage(state, payload) {
    state.items.meta.current_page = payload.value;
  },

  setNewUpdateItem(state, payload) {
    let itemIndex = state.items.data.findIndex((o) => o.id === payload.id);
    if (itemIndex !== -1) {
      state.items.data.splice(itemIndex, 1, { ...payload });
    } else {
      state.items.data.unshift(payload);
      let totalItems = state.items.data.length;
      if (state.items.meta.per_page <= totalItems) {
        state.items.data.splice(totalItems - 1, 1);
      }
    }
  },
  deleteItem(state, payload) {
    let itemIndex = state.items.data.findIndex((o) => o.id === payload);
    // Check if drawer is found
    if (itemIndex !== -1) {
      state.items.data.splice(itemIndex, 1);
    }
  },
};

const actions = {
  fetchItem: ({ commit }, id) => {
    commit("setIsItemsLoaded", false);
    return new Promise((resolve, reject) => {
      ItemAPI.fetchItem(id)
        .then((res) => {
          commit("setItem", res.data.data);
          commit("setIsItemsLoaded", true);
          resolve(res.data.data);
        })
        .catch((error) => {
          commit("setIsItemsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  fetchItems: ({ commit }, data) => {
    commit("setIsItemsLoaded", false);
    return new Promise((resolve, reject) => {
      ItemAPI.fetchItems(data)
        .then((res) => {
          commit("setItems", res.data);
          commit("setIsItemsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsItemsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  fetchItemBasic: ({ commit }, data) => {
    commit("setIsItemsLoaded", false);
    return new Promise((resolve, reject) => {
      ItemAPI.fetchItemBasic(data)
        .then((res) => {
          commit("setIsItemsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsItemsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  createItem: ({ commit }, data) => {
    commit("setIsItemsLoaded", false);
    return new Promise((resolve, reject) => {
      ItemAPI.createItem(data)
        .then((res) => {
          commit("setIsItemsLoaded", true);
          commit("setErrors", []);
          commit("setNewUpdateItem", res.data.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsItemsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updateItem: ({ commit }, data) => {
    commit("setIsItemsLoaded", false);
    return new Promise((resolve, reject) => {
      ItemAPI.updateItem(data)
        .then((res) => {
          commit("setErrors", []);
          commit("setNewUpdateItem", res.data.data);
          commit("setIsItemsLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsItemsLoaded", true);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  fetchItemBonuses: ({ commit }, id) => {
    commit("setIsItemsLoaded", false);
    return new Promise((resolve, reject) => {
      ItemAPI.fetchItemBonuses(id)
        .then((res) => {
          commit("setErrors", []);
          commit("setIsItemsLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsItemsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  createBonusItems: ({ commit }, data) => {
    commit("setIsItemsLoaded", false);
    return new Promise((resolve, reject) => {
      ItemAPI.createBonusItems(data)
        .then((res) => {
          commit("setErrors", []);
          commit("setIsItemsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsItemsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  deleteBonus: ({ commit }, id) => {
    commit("setIsItemsLoaded", false);
    return new Promise((resolve, reject) => {
      ItemAPI.deleteBonus(id)
        .then((res) => {
          commit("setErrors", []);
          commit("setIsItemsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsItemsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  deleteItem: ({ commit }, id) => {
    commit("setIsItemsLoaded", false);
    return new Promise((resolve, reject) => {
      ItemAPI.deleteItem(id)
        .then((res) => {
          commit("setErrors", []);
          commit("deleteItem", id);
          commit("setIsItemsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsItemsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  increasePrices: ({ commit }, data) => {
    commit("setIsItemsLoaded", false);
    return new Promise((resolve, reject) => {
      ItemAPI.increasePrices(data)
        .then((res) => {
          commit("setIsItemsLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsItemsLoaded", true);
          reject(error);
        });
    });
  },

  decreasePrices: ({ commit }, data) => {
    commit("setIsItemsLoaded", false);
    return new Promise((resolve, reject) => {
      ItemAPI.decreasePrices(data)
        .then((res) => {
          commit("setIsItemsLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsItemsLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
