import { RestaurantTableAPI } from "@/helpers/Apis/index";

const state = {
  tables: [],
  errors: [],
  isTablesLoaded: true,

  basicTables: [],
  restaurantTables: [],

  tableStatuses: [],
  isTableStatusesLoaded: true,
};

const getters = {
  isTablesLoaded(state) {
    return state.isTablesLoaded;
  },
  getTables(state) {
    return state.tables;
  },
  getBasicTables(state) {
    return state.basicTables;
  },
  getRestaurantTables(state) {
    return state.restaurantTables;
  },
  getTableErrors(state) {
    return state.errors;
  },

  getTableStatuses(state) {
    return state.tableStatuses;
  },
  isTableStatusesLoaded(state) {
    return state.isTableStatusesLoaded;
  },
};

const mutations = {
  setIsTablesLoaded: (state, payload) => {
    state.isTablesLoaded = payload;
  },
  setTables: (state, payload) => {
    state.tables = payload;
  },
  setBasicTables: (state, payload) => {
    state.basicTables = payload;
  },
  setRestaurantTables: (state, payload) => {
    state.restaurantTables = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setCurrentPage(state, payload) {
    state.tables.meta.current_page = payload.value;
  },

  setNewUpdateTable(state, payload) {
    let tableIndex = state.tables.data.findIndex((o) => o.id === payload.id);
    if (tableIndex !== -1) {
      state.tables.data.splice(tableIndex, 1, { ...payload });
    } else {
      state.tables.data.unshift(payload);
    }
  },
  deleteTable(state, payload) {
    let tableIndex = state.tables.data.findIndex((o) => o.id === payload);
    if (tableIndex !== -1) {
      state.tables.data.splice(tableIndex, 1);
    }
  },

  setTableStatus(state, payload) {
    let table = state.tables.data.find((o) => o.id === payload.table_id);
    table.status = payload.status;
  },

  setTableStatuses(state, payload) {
    state.tableStatuses = payload;
  },
  setIsTableStatusesLoaded(state, payload) {
    state.isTableStatusesLoaded = payload;
  },
};

const actions = {
  fetchTables: ({ commit }, data) => {
    commit("setIsTablesLoaded", false);
    return new Promise((resolve, reject) => {
      RestaurantTableAPI.fetchTables(data)
        .then((res) => {
          commit("setTables", res.data);
          commit("setIsTablesLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsTablesLoaded", true);
          reject(error);
        });
    });
  },

  fetchBasicTables: ({ commit }) => {
    commit("setIsTablesLoaded", false);
    return new Promise((resolve, reject) => {
      RestaurantTableAPI.fetchBasicTables()
        .then((res) => {
          commit("setBasicTables", res.data.data);
          commit("setIsTablesLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsTablesLoaded", true);
          reject(error);
        });
    });
  },

  fetchRestaurantTables: ({ commit }, branchId) => {
    commit("setIsTablesLoaded", false);
    return new Promise((resolve, reject) => {
      RestaurantTableAPI.fetchRestaurantTables(branchId)
        .then((res) => {
          commit("setRestaurantTables", res.data.data);
          commit("setIsTablesLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsTablesLoaded", true);
          reject(error);
        });
    });
  },

  createTable: ({ commit }, data) => {
    commit("setIsTablesLoaded", false);
    return new Promise((resolve, reject) => {
      RestaurantTableAPI.createTable(data)
        .then((res) => {
          commit("setIsTablesLoaded", true);
          commit("setErrors", []);
          commit("setNewUpdateTable", res.data.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsTablesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updateTable: ({ commit }, data) => {
    commit("setIsTablesLoaded", false);
    return new Promise((resolve, reject) => {
      RestaurantTableAPI.updateTable(data)
        .then((res) => {
          commit("setIsTablesLoaded", true);
          commit("setErrors", []);
          commit("setNewUpdateTable", res.data.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsTablesLoaded", true);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  deleteTable: ({ commit }, id) => {
    commit("setIsTablesLoaded", false);
    return new Promise((resolve, reject) => {
      RestaurantTableAPI.deleteTable(id)
        .then((res) => {
          commit("setErrors", []);
          commit("deleteTable", id);
          commit("setIsTablesLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsTablesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  setTableStatus: ({ commit }, data) => {
    commit("setIsTablesLoaded", false);
    return new Promise((resolve, reject) => {
      RestaurantTableAPI.setTableStatus(data)
        .then((res) => {
          commit("setTableStatus", res.data);
          commit("setIsTablesLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsTablesLoaded", true);
          reject(error);
        });
    });
  },

  fetchTableStatuses: ({ commit }, data) => {
    commit("setIsTableStatusesLoaded", false);
    return new Promise((resolve, reject) => {
      RestaurantTableAPI.fetchTableStatuses(data)
        .then((res) => {
          commit("setTableStatuses", res.data.data);
          commit("setIsTableStatusesLoaded", true);
          commit("setErrors", []);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsTableStatusesLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
