import axios from "axios";
import { UserAPI } from "@/helpers/Apis/User/Users";
import router from "@/router";
import VueCookies from "vue-cookies";
import i18n from "@/plugins/i18n/i18n";
import Permissions from "@/helpers/Permissions/Permissions.js";

const state = {
  user: JSON.parse(localStorage.getItem("user")) || null,
  isUserLoaded: true,
  token: VueCookies.get("token") || null,
  errors: [],
  begin_date: JSON.parse(localStorage.getItem("begin_date")) || null,

  isUserPreferencesLoaded: true,
};

const getters = {
  getUser(state) {
    return state.user;
  },
  getUserAccounts(state) {
    return state.user.accounts;
  },
  isUserLoaded(state) {
    return state.isUserLoaded;
  },
  getBeginDate(state) {
    return state.begin_date;
  },
  getAuthentication(state) {
    return state.token != null;
  },
  getErrors(state) {
    return state.errors;
  },

  permissionChecker: (state) => (value) => {
    // Memoize user permissions to avoid repeated lookups
    const userPermissions = Array.isArray(state.user?.permissions) ? state.user.permissions : [];

    // Single check function for both single and multiple permissions
    // const hasPermission = (permission) =>
    //   Permissions.includes(permission) && userPermissions.includes(permission);
    const hasPermission = (permission) => userPermissions.includes(permission);

    // Handle both single and array inputs efficiently
    return Array.isArray(value)
      ? value.some(hasPermission) // Check if any permission exists
      : hasPermission(value); // Check single permission
  },

  permissionsChecker: (state) => (value) => {
    // Memoize user permissions to avoid repeated lookups
    const userPermissions = Array.isArray(state.user?.permissions) ? state.user.permissions : [];
    // Single check function for permissions
    const hasPermission = (permission) => userPermissions.includes(permission);
    // Check if all permissions in the array exist
    return value.every(hasPermission);
  },

  isUserPreferencesLoaded(state) {
    return state.isUserPreferencesLoaded;
  },
  getUserDocumentPreferences: (state) => (value) => {
    return state.user.user_settings?.document_preferences?.[value] ?? null;
  },
  getUserDefaults: (state) => (value) => {
    return state.user.user_settings?.defaults?.[value] ?? null;
  },
  getUserAccessControl: (state) => (value) => {
    return state.user.user_settings?.access_control?.[value] ?? null;
  },
};

const mutations = {
  setUser: (state, payload) => {
    // setting user
    localStorage.setItem("user", JSON.stringify(payload.user));
    state.user = payload.user;
    // setting token
    VueCookies.set("token", JSON.stringify(payload.token), "7d");
    state.token = payload.token;

    // setting beginnning date
    localStorage.setItem("begin_date", JSON.stringify(payload.begin_date));
    state.begin_date = payload.begin_date;
  },
  setIsUserLoaded: (state, payload) => {
    state.isUserLoaded = payload;
  },

  setUserName: (state, payload) => {
    let user = JSON.parse(localStorage.getItem("user"));
    user.name = payload;
    state.user.name = payload;
    localStorage.setItem("user", JSON.stringify(user));
  },

  setDBSettings: (state, payload) => {
    state.user.database_settings = payload;
  },

  setLogout: (state) => {
    state.token = null;
    state.user = {};
    if (router.currentRoute.name != "Login") {
      router.push({
        name: "Login",
      });
    }

    //remove token from cookies
    localStorage.removeItem("user");
    localStorage.removeItem("company");
    localStorage.removeItem("begin_date");
    localStorage.removeItem("db");
    VueCookies.remove("token");
    //remove token from header
    axios.defaults.headers.common["Authorization"] = null;

    Echo.disconnect();
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setWholeAccessControl(state, payload) {
    let user = JSON.parse(localStorage.getItem("user"));
    user.user_settings.access_control = payload;

    state.user = user;
    localStorage.setItem("user", JSON.stringify(user));
  },

  // User Settings -> AccesControl(Stores,Drawers,Branches,etc..)
  setAccessControl(state, payload) {
    let data = payload.data; // The object to add or update
    let accessControlType = payload.type; // The type, e.g., branches, drawers, stores
    let user = JSON.parse(localStorage.getItem("user"));
    if (!user || !accessControlType) return;

    // Ensure the top-level array exists
    if (!user[accessControlType]) {
      user[accessControlType] = [];
    }
    // Update or add to the top-level accessControlType array
    let accessControlIndex = user[accessControlType].findIndex((o) => o.id === data.id);
    if (accessControlIndex !== -1) {
      user[accessControlType][accessControlIndex] = data; // Update existing item
    } else {
      user[accessControlType].push(data); // Add new item
    }
    // Ensure the nested access control array exists
    if (!user.user_settings?.access_control?.[accessControlType]) {
      user.user_settings.access_control[accessControlType] = [];
    }
    // Update or add the ID in the nested access control array
    let accessControlArray = user.user_settings.access_control[accessControlType];
    if (!accessControlArray.includes(data.id)) {
      accessControlArray.push(data.id);
    }
    // Update the Vuex state and local storage
    state.user = user;
    localStorage.setItem("user", JSON.stringify(user));
  },
  deleteAccessControl(state, payload) {
    let data = payload.data; // The ID to remove
    let accessControlType = payload.type; // The type, e.g., branches, drawers, stores
    let user = JSON.parse(localStorage.getItem("user"));
    if (!user || !accessControlType) return;

    // Remove from the top-level accessControlType array (if it exists)
    if (user[accessControlType]) {
      let topLevelIndex = user[accessControlType].findIndex((item) => item.id === data);
      if (topLevelIndex !== -1) {
        user[accessControlType].splice(topLevelIndex, 1);
      }
    }
    // Remove from user_settings.access_control[accessControlType] (if it exists)
    if (user.user_settings?.access_control?.[accessControlType]) {
      let accessControlArray = user.user_settings.access_control[accessControlType];
      let settingsIndex = accessControlArray.findIndex((id) => id === data);
      if (settingsIndex !== -1) {
        accessControlArray.splice(settingsIndex, 1);
      }
    }
    // Update the Vuex state and local storage
    state.user = user;
    localStorage.setItem("user", JSON.stringify(user));
  },

  setUserQuickLinks(state, payload) {
    let user = JSON.parse(localStorage.getItem("user"));
    user.quick_links = payload;
    state.user = user;
    localStorage.setItem("user", JSON.stringify(user));
  },

  refreshUserLocal(state) {
    state.user = JSON.parse(localStorage.getItem("user"));
  },
  setUserPermissions(state, payload) {
    let user = JSON.parse(localStorage.getItem("user"));
    user.permissions = payload;

    state.user = user;
    localStorage.setItem("user", JSON.stringify(user));
  },

  setIsUserPreferencesLoaded: (state, payload) => {
    state.isUserPreferencesLoaded = payload;
  },

  setUserDocumentPreferences: (state, payload) => {
    let user = JSON.parse(localStorage.getItem("user"));
    user.user_settings.document_preferences = payload;

    state.user = user;
    localStorage.setItem("user", JSON.stringify(user));
  },
  setUserAccessControl: (state, payload) => {
    let user = JSON.parse(localStorage.getItem("user"));
    user.user_settings.access_control = payload;

    state.user = user;
    localStorage.setItem("user", JSON.stringify(user));
  },
  setUserDefaults: (state, payload) => {
    let user = JSON.parse(localStorage.getItem("user"));
    user.user_settings.defaults = payload;

    state.user = user;
    localStorage.setItem("user", JSON.stringify(user));
  },
};

const actions = {
  login: ({ commit, dispatch }, data) => {
    commit("setIsUserLoaded", false);
    return new Promise((resolve, reject) => {
      UserAPI.login(data)
        .then((res) => {
          let resData = res.data.data;
          let user = res.data.data.user;
          commit("setUser", resData);
          commit("setIsUserLoaded", true);
          commit("setErrors", []);

          commit("DBSettings/setDBSettings", user.database_settings, {
            root: true,
          });
          commit("Company/setCompanyData", data.company, {
            root: true,
          });
          commit("Company/setDatabase", data.database, {
            root: true,
          });

          dispatch("Sockets/registerCompanyChannel", {}, { root: true });
          dispatch("Sockets/registerPublicChannel", {}, { root: true });

          resolve(res);
          // router.push({ name: "Home" });
          // router.push({ name: "Home" }).then(() => {
          //   window.location.reload();
          // });
          window.location.href = "/";
        })
        .catch((error) => {
          commit("setIsUserLoaded", true);
          // commit logout
          // if (error.response.status != 409) {
          //   commit("setLogout");
          // }
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  forceLogin: ({ commit, dispatch }, data) => {
    commit("setIsUserLoaded", false);
    return new Promise((resolve, reject) => {
      UserAPI.forceLogin(data)
        .then((res) => {
          let resData = res.data.data;
          let user = res.data.data.user;
          commit("setUser", resData);
          commit("setIsUserLoaded", true);
          commit("setErrors", []);

          commit("DBSettings/setDBSettings", user.database_settings, {
            root: true,
          });
          commit("Company/setCompanyData", data.company, {
            root: true,
          });
          commit("Company/setDatabase", data.database, {
            root: true,
          });

          dispatch("Sockets/registerCompanyChannel", {}, { root: true });
          dispatch("Sockets/registerPublicChannel", {}, { root: true });

          resolve(res);
          window.location.href = "/";
        })
        .catch((error) => {
          commit("setIsUserLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  fetchUser: ({ commit, dispatch }) => {
    commit("setIsUserLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    UserAPI.fetchUser()
      .then((res) => {
        commit("App/setAppLoader", false, { root: true });
        let resData = res.data.data;
        commit("setUser", resData);
        commit("setIsUserLoaded", true);
        commit("DBSettings/setDBSettings", resData.user.database_settings, {
          root: true,
        });
      })
      .catch((error) => {
        commit("setIsUserLoaded", true);
        commit("App/setAppLoader", false, { root: true });
        commit("setLogout");
      });
  },
  reloadUser: ({ commit }) => {
    commit("setIsUserLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      UserAPI.reloadUser()
        .then((res) => {
          let resData = res.data.data;
          commit("setUser", resData);
          commit("DBSettings/setDBSettings", resData.user.database_settings, {
            root: true,
          });
          commit("Company/setCompanyData", resData.company, {
            root: true,
          });

          commit("setErrors", []);
          commit("setIsUserLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsUserLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  logout: ({ commit, dispatch }) => {
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      UserAPI.logout()
        .then((res) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setLogout");
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setLogout");
          reject(error);
        });
    });
  },

  updateName: ({ commit }, data) => {
    commit("setIsUserLoaded", false);
    return new Promise((resolve, reject) => {
      UserAPI.updateName(data)
        .then((res) => {
          commit("setUserName", data.name);
          commit("setIsUserLoaded", true);
          commit("setErrors", []);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsUserLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  updatePassword: ({ commit }, data) => {
    commit("setIsUserLoaded", false);
    return new Promise((resolve, reject) => {
      UserAPI.updatePassword(data)
        .then((res) => {
          commit("setIsUserLoaded", true);
          commit("setErrors", []);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsUserLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updateQuickLinks: ({ commit }, data) => {
    commit("setIsUserLoaded", false);
    return new Promise((resolve, reject) => {
      UserAPI.updateQuickLinks(data)
        .then((res) => {
          commit("setIsUserLoaded", true);
          commit("setErrors", []);
          commit("setUserQuickLinks", res.data.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsUserLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  checkAdminPass({ commit }, data) {
    commit("setIsUserLoaded", false);
    return new Promise((resolve, reject) => {
      UserAPI.checkAdminPass(data)
        .then((res) => {
          commit("setIsUserLoaded", true);
          commit("setErrors", []);
          resolve(res);
        })
        .catch((error) => {
          // commit logout
          commit("setIsUserLoaded", true);
          reject(error.response.data);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
  checkSupportPass({ commit }, data) {
    commit("setIsUserLoaded", false);
    return new Promise((resolve, reject) => {
      UserAPI.checkSupportPass(data)
        .then((res) => {
          commit("setIsUserLoaded", true);
          commit("setErrors", []);
          resolve(res);
        })
        .catch((error) => {
          // commit logout
          commit("setIsUserLoaded", true);
          reject(error.response.data);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  updateDocumentPreferences({ commit }, data) {
    commit("setIsUserPreferencesLoaded", false);
    return new Promise((resolve, reject) => {
      UserAPI.updateDocumentPreferences(data)
        .then((res) => {
          commit("setIsUserPreferencesLoaded", true);
          commit("setErrors", []);
          commit("setUserDocumentPreferences", res.data.data);
          resolve(res);
        })
        .catch((error) => {
          // commit logout
          commit("setIsUserPreferencesLoaded", true);
          reject(error.response.data);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
