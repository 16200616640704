import auth from "@/router/middleware/auth";
import permission from "@/router/middleware/permission";
// import store from "@/store";

// function lazyLoad(folderName, view) {
//   return () => import(`@/views/Containers/${folderName}/${view}.vue`);
// }
function lazyLoad(folderName, view) {
  return () =>
    import(/* webpackChunkName: "constructions" */ `@containers/${folderName}/${view}.vue`);
}

const Constructions = [
  {
    path: "/categories",
    name: "Categories",
    component: lazyLoad("Constructions/Category", "Categories"),
    meta: {
      middleware: [auth, permission("category-view")],
    },
  },
  {
    path: "/items",
    name: "Items",
    component: lazyLoad("Constructions/Item", "Items"),
    meta: {
      middleware: [auth, permission("item-view")],
    },
  },

  {
    path: "/clients",
    name: "Clients",
    component: lazyLoad("Constructions/Client", "Clients"),
    meta: {
      middleware: [auth, permission("client-view")],
    },
  },

  {
    path: "/suppliers",
    name: "Suppliers",
    component: lazyLoad("Constructions/Supplier", "Suppliers"),
    meta: {
      middleware: [auth, permission("supplier-view")],
    },
  },
  {
    path: "/stores",
    name: "Stores",
    component: lazyLoad("Constructions/Store", "Stores"),
    meta: {
      middleware: [auth, permission("store-view")],
    },
  },
  {
    path: "/branches",
    name: "Branches",
    component: lazyLoad("Constructions/Branch", "Branches"),
    meta: {
      middleware: [auth, permission("branch-view")],
    },
  },
  {
    path: "/drawers",
    name: "Drawers",
    component: lazyLoad("Constructions/Drawer", "Drawers"),
    meta: {
      middleware: [auth, permission("drawer-view")],
    },
  },
  {
    path: "/employees",
    name: "Employees",
    component: lazyLoad("Constructions/Employees", "Employees"),
    meta: {
      middleware: [auth, permission("employee-view")],
    },
  },
  {
    path: "/mainaccounts",
    name: "MainAccounts",
    component: lazyLoad("Constructions/MainAcc", "MainAccs"),
    meta: {
      middleware: [auth, permission("mainacc-view")],
    },
  },
  {
    path: "/subaccounts",
    name: "SubAccounts",
    component: lazyLoad("Constructions/SubAcc", "SubAccs"),
    meta: {
      middleware: [auth, permission("subacc-view")],
    },
  },
  {
    path: "/fixedassets",
    name: "FixedAssets",
    component: lazyLoad("Constructions/FixedAssets", "FixedAssets"),
    meta: {
      middleware: [auth, permission("fixedassets-view")],
    },
  },
  {
    path: "/beginning",
    name: "Beginning",
    component: lazyLoad("Beginning", "Beginning"),
    meta: {
      middleware: [auth, permission(["beginningstore-view", "beginningbalance-view"], "OR")],
    },
  },
];

export default Constructions;
