import baseUrl from "@/constants/API";

export const InventoryAPI = {
  fetchInventoryCount(data) {
    return axios.post(baseUrl() + `/inventory/inventorycount?page=${data.pageNumber}`, data.data);
    // return axios.post(baseUrl() + "/inventory/inventorycount", data);
  },
  fetchGeneralInventoryCount(data) {
    return axios.post(
      baseUrl() + `/inventory/generalinventorycount?page=${data.pageNumber}`,
      data.data
    );
  },

  fetchRestockAlert(data) {
    return axios.post(baseUrl() + `/inventory/restock-alert?page=${data.pageNumber}`, data.data);
  },

  fetchEndInventoryCount(data) {
    return axios.post(baseUrl() + "/inventory/endinventorycount", data);
  },
};
