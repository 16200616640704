import Vue from "vue";
import { mapGetters } from "vuex";
import {
  InvoiceTypeIds,
  InvoiceTypes,
  EntryTypes,
  partTypes,
  PriceTypes,
  BalanceTypes,
} from "@/helpers/Constants/General";
import { CompanyPermissions } from "@/helpers/Permissions/CompanyPermissions";
// import Permissions from "@/helpers/Permissions/Permissions.js";

const globalMixins = {
  computed: {
    ...mapGetters("User", [
      "getUser",
      "getUserAccounts",
      "isUserLoaded",
      "getBeginDate",
      "permissionChecker",
      "permissionsChecker",
      "getUserDocumentPreferences",
      "getUserDefaults",
      "getUserAccessControl",
    ]),
    ...mapGetters("Company", ["getCompanyData", "companyLevelChecker", "companyPermissionChecker"]),
    ...mapGetters("App", ["getLanguage"]),
    databaseSettings() {
      return this.getUser.database_settings;
    },
    getDarkMode() {
      return this.$vuetify.theme.dark;
    },

    salesAgents() {
      let jobId = this.getUserAccounts?.database_defaults.find(
        (o) => o.name == "employee_sales_job"
      )?.account_id;
      return this.getUser?.employees.filter(function (el) {
        return el.employee_job_id == jobId;
      });
    },
    couriers() {
      let jobId = this.getUserAccounts?.database_defaults.find(
        (o) => o.name == "employee_courier_job"
      )?.account_id;
      return this.getUser?.employees.filter(function (el) {
        return el.employee_job_id == jobId;
      });
    },

    priceTypes() {
      return PriceTypes;
    },
    balanceTypes() {
      return BalanceTypes;
    },
    partTypes() {
      return partTypes;
    },
    InvoiceTypeIds() {
      return InvoiceTypeIds;
    },
    InvoiceTypes() {
      return InvoiceTypes;
    },
    EntryTypes() {
      return EntryTypes;
    },
    CompanyPermissions() {
      return CompanyPermissions;
    },
    // isAuthenticated() {
    //   return store.getters["User/getAuthentication"];
    // },
    isAdmin() {
      return this.getUser?.role?.id == 1;
    },

    allowChangeDate() {
      return this.permissionChecker("admin")
        ? true
        : !this.permissionChecker("general-disallowchangingtodaydate");
    },
    maxAllowedDate() {
      if (this.permissionChecker("admin")) {
        return null;
      }

      return this.permissionChecker("general-disallowmovementaftertoday") ? this.today() : null;
    },
  },
  methods: {
    toFixedIfNecessary(value, dp) {
      return +parseFloat(value).toFixed(dp);
    },
    // formatNumberWithCommas(number) {
    //   // if (Math.abs(number) < 1000) {
    //   //   return number;
    //   // }
    //   // return new Intl.NumberFormat("en-US").format(number);

    //   return new Intl.NumberFormat("en-US").format(number);
    // },

    // hasPermission(value) {
    //   let userPermissions = this.getUser?.permissions;
    //   let permissions = Array.isArray(userPermissions) ? userPermissions : [];
    //   if (permissions?.includes(value)) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },

    // roleChecker(value) {
    //   let role = this.getUser?.role?.name;
    //   if (role == value) {
    //     // if (role == value || role == "Admin") {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    today() {
      return new Date().toISOString().substr(0, 10);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      // return `${day}-${month}-${year}`;
      return `${year}-${month}-${day}`;
    },
    // isValidDate(value) {
    //   // First check if the value is a valid string or date object
    //   if (Object.prototype.toString.call(value) === "[object Date]" || typeof value === "string") {
    //     const date = new Date(value);
    //     // Ensure that the date object is valid
    //     return !isNaN(date.getTime());
    //   }
    //   return false;
    // },

    customerDName(value) {
      switch (value) {
        case 1:
          return this.$t("client");
        case 2:
          return this.$t("supplier");
        case 3:
          return this.$t("employee");
        // case 4:
        //   return this.$t("owner");
        case 5:
          return this.$t("maintenanceclient");
        case 6:
          return this.$t("cleanningclients");
        default:
          return this.$t("part");
      }
    },

    // toFindDuplicates(arry) {
    //   // to get duplicated objects in an array
    //   const uniqueElements = new Set(arry);
    //   const filteredElements = arry.filter((item) => {
    //     if (uniqueElements.has(item)) {
    //       uniqueElements.delete(item);
    //     } else {
    //       return item;
    //     }
    //   });

    //   return [...new Set(uniqueElements)];
    // },

    focus(El) {
      if (this.$refs[El]) {
        this.$refs[El].$el.querySelector("input").select();
      }
    },

    serialsMap(detail) {
      let serials = detail.serials || detail.newSerials;
      if (serials) {
        return serials
          .map(function (elem) {
            return elem.serial;
          })
          .join(" , ");
      }
      return "";
    },
  },
};

Vue.mixin(globalMixins);
