import { TransferAPI } from "@/helpers/Apis/StoreTransfers/StoreTransfers";
import i18n from "@/plugins/i18n/i18n";

const state = {
  reportData: [],
  errors: [],
  isTransferReportLoaded: true,

  SearchObject: {
    dates: {
      label: "date",
      value: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)],
    },
  },
};

const getters = {
  isTransferReportLoaded(state) {
    return state.isTransferReportLoaded;
  },
  getReportData(state) {
    return state.reportData;
  },
  getErrors(state) {
    return state.errors;
  },

  getSearchObject(state) {
    return state.SearchObject;
  },
};

const mutations = {
  setIsTransferReportLoaded: (state, payload) => {
    state.isTransferReportLoaded = payload;
  },
  setReportData: (state, payload) => {
    state.reportData = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setSearchObject(state, payload) {
    state.SearchObject = payload;
  },
};

const actions = {
  storeTransferSearch: ({ commit }, data) => {
    commit("setIsTransferReportLoaded", false);
    TransferAPI.storeTransferSearch(data)
      .then((res) => {
        commit("setReportData", res.data);
        commit("setIsTransferReportLoaded", true);
        commit("setErrors", []);
      })
      .catch((error) => {
        commit("setIsTransferReportLoaded", true);
        let nameError = Object.values(error.response.data.errors).flat();
        commit("setErrors", nameError[0]);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
