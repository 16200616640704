import { ReservationDetailAPI } from "@/helpers/Apis/Reservations/ReservationDetails";
import i18n from "@/plugins/i18n/i18n";

const state = {
  errors: [],
  isReservationDetailsLoaded: true,
};

const getters = {
  isReservationDetailsLoaded(state) {
    return state.isReservationDetailsLoaded;
  },
  getReservationDetailErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsReservationDetailsLoaded: (state, payload) => {
    state.isReservationDetailsLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
};

const actions = {
  checkDeleteDetail: ({ commit }, data) => {
    commit("setIsReservationDetailsLoaded", false);
    return new Promise((resolve, reject) => {
      ReservationDetailAPI.checkDeleteDetail(data)
        .then((res) => {
          commit("setErrors", []);
          commit("setIsReservationDetailsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsReservationDetailsLoaded", true);
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  deleteReservationDetail: ({ commit }, id) => {
    commit("setIsReservationDetailsLoaded", false);
    return new Promise((resolve, reject) => {
      ReservationDetailAPI.deleteReservationDetail(id)
        .then((res) => {
          commit("setErrors", []);
          commit("setIsReservationDetailsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsReservationDetailsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
