import * as APIs from "@/helpers/Apis/Stores/StoreDetails";
import i18n from "@/plugins/i18n/i18n";

const state = {
  data: [],
  errors: [],
  isStoreReportLoaded: true,

  currentPage: null,
};

const getters = {
  isStoreReportLoaded(state) {
    return state.isStoreReportLoaded;
  },
  getData(state) {
    return state.data;
  },
  getErrors(state) {
    return state.errors;
  },
  getCurrentPage(state) {
    return state.currentPage;
  },
};

const mutations = {
  setIsStoreReportLoaded: (state, payload) => {
    state.isStoreReportLoaded = payload;
  },
  setData: (state, payload) => {
    state.data = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setCurrentPage(state, payload) {
    state.data.meta.current_page = payload.value;
  },
};

const actions = {
  getDataAction: ({ commit }, data) => {
    commit("setIsStoreReportLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.getData(data)
        .then((res) => {
          commit("setData", res.data);
          commit("setIsStoreReportLoaded", true);
          commit("setErrors", []);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsStoreReportLoaded", true);
          let nameError = Object.values(error.response.data.errors).flat();
          commit("setErrors", nameError[0]);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
