import Vue from "vue";
import VueRouter from "vue-router";
import GeneralRoutes from "./routes/GeneralRoutes";
import UserRoutes from "./routes/UserRoutes";
import Constructions from "./routes/Constructions";
import SaleRoutes from "./routes/SaleRoutes";
import PurchaseRoutes from "./routes/PurchaseRoutes";
import StoreInOutTransferRoutes from "./routes/StoreInOutTransferRoutes";
import OrderRoutes from "./routes/OrderRoutes";
import ReservationRoutes from "./routes/ReservationRoutes";
import MaintenanceRoutes from "./routes/MaintenanceRoutes";
import TransactionReportRoutes from "./routes/TransactionReportRoutes";
import Entries from "./routes/Entries";
import CleanningRoutes from "./routes/CleanningRoutes";
import ProductionRoutes from "./routes/ProductionRoutes";
import RestaurantRoutes from "./routes/RestaurantRoutes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    ...GeneralRoutes,
    ...UserRoutes,
    ...Constructions,
    ...SaleRoutes,
    ...PurchaseRoutes,
    ...StoreInOutTransferRoutes,
    ...OrderRoutes,
    ...ReservationRoutes,
    ...MaintenanceRoutes,
    ...TransactionReportRoutes,
    ...Entries,
    ...ProductionRoutes,
    ...CleanningRoutes,
    ...RestaurantRoutes,

    {
      path: "*",
      name: "404",
      component: () => import("@/views/Containers/404/404.vue"),
    },
  ],
});

/*
 * Global router guard.
 */
router.beforeEach((to, from, next) => {
  // Gather all middleware from matched routes
  const middleware = to.matched.flatMap((record) => record.meta.middleware || []).filter(Boolean);

  if (middleware.length) {
    const context = { from, next, router, to };
    const nextMiddleware = nextFactory(context, middleware, 1);
    return middleware[0]({ ...context, next: nextMiddleware });
  }
  return next();
});
/*
 * A function to run the default 'next()' callback and triggers the subsequent middleware function.
 */
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];

  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run default Vue Router 'next()' callback first
    context.next(...parameters);

    if (parameters.length === 0) {
      // Proceed to next middleware
      const nextMiddleware = nextFactory(context, middleware, index + 1);
      subsequentMiddleware({ ...context, next: nextMiddleware });
    }
  };
}

// const userHasPermission = (next, route) => {
//   if (userData.permissions.includes(route)) next();
//   else {
//     notifyVue("You are not authorized to view this page", "danger");
//     next("/dashboard");
//   }
// };
// then use it in beforeEnter in Router (next, route) in params

export default router;
