import { ItemAPI } from "@/helpers/Apis/Item/Items";
import i18n from "@/plugins/i18n/i18n";

const state = {
  itemslistprice: [],
  errors: [],
  isItemsListPriceLoaded: true,

  currentPage: null,
};

const getters = {
  getItemsListPrice(state) {
    return state.itemslistprice;
  },
  isItemsListPriceLoaded(state) {
    return state.isItemsListPriceLoaded;
  },
  getItemErrors(state) {
    return state.errors;
  },
  getCurrentPage(state) {
    return state.currentPage;
  },
};

const mutations = {
  setIsItemsListPriceLoaded: (state, payload) => {
    state.isItemsListPriceLoaded = payload;
  },
  setItemsListPrice: (state, payload) => {
    state.itemslistprice = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setCurrentPage(state, payload) {
    state.itemslistprice.meta.current_page = payload.value;
  },
};

const actions = {
  ItemsListPrice: ({ commit }, data) => {
    commit("setIsItemsListPriceLoaded", false);
    return new Promise((resolve, reject) => {
      ItemAPI.ItemsListPrice(data)
        .then((res) => {
          commit("setItemsListPrice", res.data);
          commit("setIsItemsListPriceLoaded", true);
          resolve(res.data.data);
        })
        .catch((error) => {
          commit("setIsItemsListPriceLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
