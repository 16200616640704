import { RestaurantReservationAPI } from "@/helpers/Apis/index";
import { ReservationStatusesArray } from "@/helpers/Constants/Restaurant";
import i18n from "@/plugins/i18n/i18n";
import router from "@/router";

const state = {
  restaurantReservation: {},
  errors: [],
  isRestaurantReservationLoaded: true,
};

const getters = {
  isRestaurantReservationLoaded(state) {
    return state.isRestaurantReservationLoaded;
  },
  getRestaurantReservation(state) {
    return state.restaurantReservation;
  },

  getRestaurantReservationErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsRestaurantReservationLoaded: (state, payload) => {
    state.isRestaurantReservationLoaded = payload;
  },
  setRestaurantReservation: (state, payload) => {
    state.restaurantReservation = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setRestaurantReservationStatus: (state, payload) => {
    state.restaurantReservation.status = payload.id;
    state.restaurantReservation.status_name = payload.name;
  },
};

const actions = {
  fetchRestaurantReservation: ({ commit }, id) => {
    commit("setIsRestaurantReservationLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      RestaurantReservationAPI.fetchRestaurantReservation(id)
        .then((res) => {
          commit("setRestaurantReservation", res.data.data);
          commit("setIsRestaurantReservationLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", []);
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsRestaurantReservationLoaded", true);
          reject(error);
        });
    });
  },
  previousInvoice: ({ commit }, id) => {
    commit("setIsRestaurantReservationLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      RestaurantReservationAPI.previousRestaurantReservation(id)
        .then((res) => {
          commit("setRestaurantReservation", res.data.data);
          commit("setIsRestaurantReservationLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", []);

          router.push({
            path: `/restaurant/reservations/${res.data.data.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsRestaurantReservationLoaded", true);
          reject(error);
        });
    });
  },
  nextInvoice: ({ commit }, id) => {
    commit("setIsRestaurantReservationLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      RestaurantReservationAPI.nextRestaurantReservation(id)
        .then((res) => {
          commit("setRestaurantReservation", res.data.data);
          commit("setIsRestaurantReservationLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", []);
          router.push({
            path: `/restaurant/reservations/${res.data.data.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsRestaurantReservationLoaded", true);
          reject(error);
        });
    });
  },

  createRestaurantReservation: ({ commit }, data) => {
    commit("setIsRestaurantReservationLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      RestaurantReservationAPI.createRestaurantReservation(data)
        .then((res) => {
          commit("setErrors", []);
          commit("setIsRestaurantReservationLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setRestaurantReservation", invoice);
          router.push({
            path: `/restaurant/reservations/${invoice.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsRestaurantReservationLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  updateRestaurantReservation: ({ commit }, data) => {
    commit("setIsRestaurantReservationLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      RestaurantReservationAPI.updateRestaurantReservation(data)
        .then((res) => {
          commit("setErrors", []);
          commit("setIsRestaurantReservationLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setRestaurantReservation", invoice);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsRestaurantReservationLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  setRestaurantReservationStatus: ({ commit }, data) => {
    commit("setIsRestaurantReservationLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      RestaurantReservationAPI.setRestaurantReservationStatus(data)
        .then((res) => {
          commit("setErrors", []);
          commit("setIsRestaurantReservationLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let status = ReservationStatusesArray.find((o) => o.id === data.status);
          commit("setRestaurantReservationStatus", status);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsRestaurantReservationLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  deleteRestaurantReservation: ({ commit }, data) => {
    commit("setIsRestaurantReservationLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      RestaurantReservationAPI.deleteRestaurantReservation(data)
        .then((res) => {
          commit("setRestaurantReservation", {});
          commit("setErrors", []);
          commit("setIsRestaurantReservationLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsRestaurantReservationLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  restaurantReservationConvertToOrder: ({ commit }, data) => {
    commit("setIsRestaurantReservationLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      RestaurantReservationAPI.restaurantReservationConvertToOrder(data)
        .then((res) => {
          commit("setErrors", []);
          commit("setIsRestaurantReservationLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          router.push({
            path: `/restaurant/orders/${data.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsRestaurantReservationLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
