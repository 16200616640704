import { InvoiceTypeIds } from "@/helpers/Constants/General";
import { MaintenanceAPI } from "@/helpers/Apis/Maintenance/Maintenances";
import { toFixedIfNecessary } from "@/helpers/HelperFunctions";
import { EntryDetailAPI } from "@/helpers/Apis/Entries/EntryDetails";
import router from "@/router";

const state = {
  maintenance: {},

  errors: [],
  isMaintenanceLoaded: true,
  isEntriesLoaded: true,
};

const getters = {
  getMaintenance(state) {
    return state.maintenance;
  },
  isMaintenanceLoaded(state) {
    return state.isMaintenanceLoaded;
  },

  getMaintenanceErrors(state) {
    return state.errors;
  },

  isEntriesLoaded(state) {
    return state.isEntriesLoaded;
  },
};

const mutations = {
  setMaintenance: (state, payload) => {
    state.maintenance = payload;
  },

  setIsMaintenanceLoaded: (state, payload) => {
    state.isMaintenanceLoaded = payload;
  },

  setReceivedStatus: (state) => {
    state.maintenance.received_status = !state.maintenance.received_status;
  },
  setMaintenanceStatus: (state, payload) => {
    state.maintenance.maintenance_status = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },

  addPayment(state, payload) {
    state.maintenance.paid = +state.maintenance.paid + +payload.payment;
    state.maintenance.drawer = payload.drawer;
  },

  // Details Mutations
  addMaintenanceItem(state, payload) {
    state.maintenance.invoice_details.push(payload);
  },
  updateMaintenanceItem(state, payload) {
    console.log("test update");
  },
  deleteMaintenanceItem(state, payload) {
    let index = state.maintenance.invoice_details.findIndex((object) => {
      return object.id === payload;
    });
    state.maintenance.invoice_details.splice(index, 1);

    // state.maintenance.invoice_entries = state.maintenance.invoice_entries.filter(
    //   (item) => item.transaction_id !== payload
    // );
  },
  calcMaintenanceItems(state) {
    let spareParts = state.maintenance.invoice_details.reduce(
      (a, b) => toFixedIfNecessary(a, 2) + (toFixedIfNecessary(b["total"], 2) || 0),
      0
    );
    state.maintenance.spare_parts = spareParts;
  },

  // Questions Mutations
  addMaintenanceQuestion(state, payload) {
    state.maintenance.questions.push(payload);
  },
  removeMaintenanceQuestion(state, payload) {
    let questionIndex = state.maintenance.questions.findIndex((object) => {
      return object.id === payload;
    });
    state.maintenance.questions.splice(questionIndex, 1);
  },
  removeMaintenanceQuestionAnswer(state, payload) {
    let questionIndex = state.maintenance.questions.findIndex((object) => {
      return object.id === payload.maintenance_question_id;
    });
    let question = state.maintenance.questions[questionIndex];
    let answerIndex = question.answers.findIndex((object) => {
      return object.id === payload.payload.id;
    });

    state.maintenance.questions[questionIndex].answers.splice(answerIndex, 1);
  },

  setMaintenanceExpenses(state, payload) {
    state.maintenance.expenses = payload;
  },
  setIsEntriesLoaded(state, payload) {
    state.isEntriesLoaded = payload;
  },
};

const actions = {
  fetchMaintenance: ({ commit }, id) => {
    commit("setIsMaintenanceLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      MaintenanceAPI.fetchMaintenance(id)
        .then((res) => {
          commit("setMaintenance", res.data.data);
          commit("App/setAppLoader", false, { root: true });
          commit("setIsMaintenanceLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsMaintenanceLoaded", true);
          reject(error);
        });
    });
  },

  createMaintenance: ({ commit }, data) => {
    commit("setIsMaintenanceLoaded", false);
    return new Promise((resolve, reject) => {
      MaintenanceAPI.createMaintenance(data)
        .then((res) => {
          commit("setIsMaintenanceLoaded", true);
          let maintenance = res.data.data;
          commit("setMaintenance", maintenance);
          router.push({
            path: `/maintenances/${maintenance.id}`,
          });

          commit("setErrors", []);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMaintenanceLoaded", true);
          reject(error.response);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  updateMaintenance: ({ commit }, data) => {
    commit("setIsMaintenanceLoaded", false);
    return new Promise((resolve, reject) => {
      MaintenanceAPI.updateMaintenance(data)
        .then((res) => {
          commit("setIsMaintenanceLoaded", true);
          let maintenance = res.data.data;
          commit("setMaintenance", maintenance);
          commit("setErrors", []);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMaintenanceLoaded", true);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  deleteMaintenance: ({ commit }, id) => {
    commit("setIsMaintenanceLoaded", false);
    return new Promise((resolve, reject) => {
      MaintenanceAPI.deleteMaintenance(id)
        .then((res) => {
          commit("setErrors", []);
          commit("setIsMaintenanceLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMaintenanceLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  changeReceiveStatus: ({ commit }, id) => {
    commit("setIsMaintenanceLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      MaintenanceAPI.changeReceiveStatus(id)
        .then((res) => {
          commit("setErrors", []);
          commit("setIsMaintenanceLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setReceivedStatus");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMaintenanceLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  changeMaintenanceStatus: ({ commit }, data) => {
    commit("setIsMaintenanceLoaded", false);
    return new Promise((resolve, reject) => {
      MaintenanceAPI.changeMaintenanceStatus(data)
        .then((res) => {
          commit("setErrors", []);
          commit("setIsMaintenanceLoaded", true);
          commit("setMaintenanceStatus", data.maintenance_status);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMaintenanceLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  payMaintenance: ({ commit }, data) => {
    commit("setIsMaintenanceLoaded", false);
    return new Promise((resolve, reject) => {
      MaintenanceAPI.payMaintenance(data)
        .then((res) => {
          commit("setErrors", []);
          commit("setIsMaintenanceLoaded", true);
          commit("addPayment", data.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMaintenanceLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  createCustomEntry: ({ state, commit }, data) => {
    commit("setIsEntriesLoaded", false);
    let invoiceData = state.maintenance;
    let mergedData = { ...data, invoiceData: invoiceData };
    return new Promise((resolve, reject) => {
      EntryDetailAPI.createCustomEntry(mergedData)
        .then((res) => {
          commit("setIsEntriesLoaded", true);
          commit("setErrors", []);
          // console.log(res.data.data);
          // commit("addMaintenanceEntries", res.data.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEntriesLoaded", true);
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
