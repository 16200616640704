import { RestaurantShiftAPI } from "@/helpers/Apis/index";

const state = {
  restaurantShifts: [],
  errors: [],
  isRestaurantShiftsLoaded: true,
};

const getters = {
  isRestaurantShiftsLoaded(state) {
    return state.isRestaurantShiftsLoaded;
  },
  getRestaurantShifts(state) {
    return state.restaurantShifts;
  },
  getShiftErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsRestaurantShiftsLoaded: (state, payload) => {
    state.isRestaurantShiftsLoaded = payload;
  },
  setRestaurantShifts: (state, payload) => {
    state.restaurantShifts = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setNewUpdateShift(state, payload) {
    let shiftIndex = state.restaurantShifts.findIndex((o) => o.id === payload.id);
    if (shiftIndex !== -1) {
      state.restaurantShifts.splice(shiftIndex, 1, { ...payload });
    } else {
      state.restaurantShifts.push(payload);
    }
  },
  deleteRestaurantShift(state, payload) {
    let shiftIndex = state.restaurantShifts.findIndex((o) => o.id === payload);
    if (shiftIndex !== -1) {
      state.restaurantShifts.splice(shiftIndex, 1);
    }
  },
};

const actions = {
  fetchRestaurantShifts: ({ commit }, data) => {
    commit("setIsRestaurantShiftsLoaded", false);
    return new Promise((resolve, reject) => {
      RestaurantShiftAPI.fetchRestaurantShifts(data)
        .then((res) => {
          commit("setErrors", []);
          commit("setRestaurantShifts", res.data.data);
          commit("setIsRestaurantShiftsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsRestaurantShiftsLoaded", true);
          reject(error);
        });
    });
  },

  createRestaurantShift: ({ commit }, data) => {
    commit("setIsRestaurantShiftsLoaded", false);
    return new Promise((resolve, reject) => {
      RestaurantShiftAPI.createRestaurantShift(data)
        .then((res) => {
          commit("setErrors", []);
          commit("setIsRestaurantShiftsLoaded", true);
          commit("setNewUpdateShift", res.data.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsRestaurantShiftsLoaded", true);
          let errors = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", errors);
          reject(error);
        });
    });
  },

  updateRestaurantShift: ({ commit }, data) => {
    commit("setIsRestaurantShiftsLoaded", false);
    return new Promise((resolve, reject) => {
      RestaurantShiftAPI.updateRestaurantShift(data)
        .then((res) => {
          commit("setErrors", []);
          commit("setIsRestaurantShiftsLoaded", true);
          commit("setNewUpdateShift", res.data.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsRestaurantShiftsLoaded", true);

          let errors = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", errors);
          reject(error);
        });
    });
  },
  deleteRestaurantShift: ({ commit }, id) => {
    commit("setIsRestaurantShiftsLoaded", false);
    return new Promise((resolve, reject) => {
      RestaurantShiftAPI.deleteRestaurantShift(id)
        .then((res) => {
          commit("setErrors", []);
          commit("deleteRestaurantShift", id);
          commit("setIsRestaurantShiftsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsRestaurantShiftsLoaded", true);
          let errors = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", errors);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
