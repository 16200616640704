import { RestaurantOrderAPI } from "@/helpers/Apis/index";
import { StatusesArray } from "@/helpers/Constants/Restaurant";
import i18n from "@/plugins/i18n/i18n";
import router from "@/router";
import { toFixedIfNecessary } from "@/helpers/HelperFunctions";

const state = {
  restaurantOrder: {},

  errors: [],
  orderDefaults: {
    branch: null,
    store: null,
    part: null,
    sub_account: null,
    restaurantShift: null,
  },

  restaurantOrderDetailsErrors: [],
  isRestaurantOrdersLoaded: true,

  isOrderMaterialsLoaded: true,
};

const getters = {
  isRestaurantOrdersLoaded(state) {
    return state.isRestaurantOrdersLoaded;
  },
  getRestaurantOrder(state) {
    return state.restaurantOrder;
  },

  getRestaurantOrderErrors(state) {
    return state.errors;
  },

  getRestaurantOrderDetailsErrors(state) {
    return state.restaurantOrderDetailsErrors;
  },

  getOrderDefaults(state) {
    return state.orderDefaults;
  },

  isOrderMaterialsLoaded(state) {
    return state.isOrderMaterialsLoaded;
  },
};

const mutations = {
  setIsRestaurantOrdersLoaded: (state, payload) => {
    state.isRestaurantOrdersLoaded = payload;
  },
  setRestaurantOrder: (state, payload) => {
    state.restaurantOrder = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setRestaurantOrderStatus: (state, payload) => {
    state.restaurantOrder.status = payload.id;
    state.restaurantOrder.status_name = payload.name;
  },
  setRestaurantOrderPayment: (state, payload) => {
    state.restaurantOrder.paid = payload.paid;
    state.restaurantOrder.pay_type = payload.pay_type;
    state.restaurantOrder.drawer_id = payload.drawer_id;
  },

  setOrderDefaults: (state, payload) => {
    state.orderDefaults = payload;
  },

  setIsOrderMaterialsLoaded: (state, payload) => {
    state.isOrderMaterialsLoaded = payload;
  },

  reCalcOrder(state) {
    let mealsTotal = state.restaurantOrder.order_meals.reduce((sum, item) => sum + +item.total, 0);
    let addonsTotal = state.restaurantOrder.order_addons.reduce(
      (sum, item) => sum + +item.total,
      0
    );
    // order items total
    let total = toFixedIfNecessary(mealsTotal + addonsTotal, 2);
    state.restaurantOrder.total = total;

    // calc taxes
    let taxValue = toFixedIfNecessary(total * (+state.restaurantOrder.tax_ratio / 100), 2);
    state.restaurantOrder.tax_value = taxValue;
    total += taxValue;

    // calc service
    let serviceValue = toFixedIfNecessary(total * (+state.restaurantOrder.service_ratio / 100), 2);
    state.restaurantOrder.service_value = serviceValue;
    total += serviceValue;

    // calc discount
    let discountValue = toFixedIfNecessary(
      total * (+state.restaurantOrder.discount_ratio / 100),
      2
    );
    state.restaurantOrder.discount_value = discountValue;
    total -= discountValue;

    state.restaurantOrder.net = total;
    state.restaurantOrder.remaining = total - state.restaurantOrder.paid;
  },

  addOrderAddon: (state, payload) => {
    state.restaurantOrder.order_addons.push(payload);
  },
  removeOrderAddon: (state, payload) => {
    state.restaurantOrder.order_addons = payload;

    let addonIndex = state.restaurantOrder.order_addons.findIndex((o) => o.id === payload);
    if (addonIndex !== -1) {
      // if transaction found
      state.restaurantOrder.order_addons.splice(addonIndex, 1); // Remove addon
    }
  },
};

const actions = {
  fetchRestaurantOrder: ({ commit }, id) => {
    commit("setIsRestaurantOrdersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      RestaurantOrderAPI.fetchRestaurantOrder(id)
        .then((res) => {
          let invoice = res.data.data;
          commit("setRestaurantOrder", invoice);
          commit("setIsRestaurantOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", []);
          if (router.currentRoute.path !== `/restaurant/orders/${invoice.id}`) {
            router.push({
              path: `/restaurant/orders/${invoice.id}`,
            });
          }
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsRestaurantOrdersLoaded", true);
          reject(error);
        });
    });
  },

  createRestaurantOrder: ({ commit }, data) => {
    commit("setIsRestaurantOrdersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      RestaurantOrderAPI.createRestaurantOrder(data)
        .then((res) => {
          commit("setErrors", []);
          commit("setIsRestaurantOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setRestaurantOrder", invoice);
          if (router.currentRoute.path !== `/restaurant/orders/${invoice.id}`) {
            router.push({
              path: `/restaurant/orders/${invoice.id}`,
            });
          }
          resolve(res);
        })
        .catch((error) => {
          commit("setIsRestaurantOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  updateRestaurantOrder: ({ commit }, data) => {
    commit("setIsRestaurantOrdersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      RestaurantOrderAPI.updateRestaurantOrder(data)
        .then((res) => {
          commit("setErrors", []);
          commit("setIsRestaurantOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setRestaurantOrder", invoice);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsRestaurantOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  setRestaurantOrderStatus: ({ commit }, data) => {
    commit("setIsRestaurantOrdersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      RestaurantOrderAPI.setRestaurantOrderStatus(data)
        .then((res) => {
          commit("setErrors", []);
          commit("setIsRestaurantOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let status = StatusesArray.find((o) => o.id === data.status);
          commit("setRestaurantOrderStatus", status);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsRestaurantOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
  restaurantOrderPayment: ({ commit }, data) => {
    commit("setIsRestaurantOrdersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      RestaurantOrderAPI.restaurantOrderPayment(data)
        .then((res) => {
          commit("setErrors", []);
          commit("setIsRestaurantOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setRestaurantOrderPayment", data.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsRestaurantOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  deleteRestaurantOrder: ({ commit }, data) => {
    commit("setIsRestaurantOrdersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      RestaurantOrderAPI.deleteRestaurantOrder(data)
        .then((res) => {
          commit("setRestaurantOrder", {});
          commit("setErrors", []);
          commit("setIsRestaurantOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsRestaurantOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors).flat()
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  fetchRestaurantOrderDailySearch: ({ commit }, dailyInvoiceNumber) => {
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      RestaurantOrderAPI.fetchRestaurantOrderDailySearch(dailyInvoiceNumber)
        .then((res) => {
          let invoice = res.data.data;
          commit("setRestaurantOrder", invoice);
          commit("App/setAppLoader", false, { root: true });
          if (router.currentRoute.path !== `/restaurant/orders/${invoice.id}`) {
            router.push({
              path: `/restaurant/orders/${invoice.id}`,
            });
          }
          commit("setErrors", []);
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          reject(error);
        });
    });
  },

  createRestaurantOrderAddon: ({ commit }, data) => {
    commit("setIsOrderMaterialsLoaded", false);
    return new Promise((resolve, reject) => {
      RestaurantOrderAPI.createRestaurantOrderAddon(data)
        .then((res) => {
          let transaction = res.data.data;
          commit("addOrderAddon", transaction);
          commit("reCalcOrder");

          commit("setIsOrderMaterialsLoaded", true);
          commit("setErrors", []);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsOrderMaterialsLoaded", true);
          reject(error);
        });
    });
  },
  deleteRestaurantOrderAddon: ({ commit }, id) => {
    commit("setIsOrderMaterialsLoaded", false);
    return new Promise((resolve, reject) => {
      RestaurantOrderAPI.deleteRestaurantOrderAddon(id)
        .then((res) => {
          commit("removeOrderAddon", id);
          commit("reCalcOrder");
          commit("setIsOrderMaterialsLoaded", true);
          commit("setErrors", []);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsOrderMaterialsLoaded", true);
          reject(error);
        });
    });
  },

  fetchOrderMaterials: ({ commit }, id) => {
    commit("setIsOrderMaterialsLoaded", false);
    return new Promise((resolve, reject) => {
      RestaurantOrderAPI.fetchOrderMaterials(id)
        .then((res) => {
          let materials = res.data.data;
          commit("setIsOrderMaterialsLoaded", true);
          commit("setErrors", []);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsOrderMaterialsLoaded", true);
          reject(error);
        });
    });
  },

  fetchDelveringOrders: ({ commit }, branchId) => {
    commit("setIsOrderMaterialsLoaded", false);
    return new Promise((resolve, reject) => {
      RestaurantOrderAPI.fetchDelveringOrders(branchId)
        .then((res) => {
          let orders = res.data.data;
          commit("setIsOrderMaterialsLoaded", true);
          commit("setErrors", []);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsOrderMaterialsLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
